import { get, del } from "../../../../config/apiHelper";
import FormularioAdjuntarArchivo from "./FormularioAdjuntarArchivo";

const MostrarArchivosAdjuntos = (props) => {

  const { archivosAdjuntos } = props;

  const obtenerArchivo = async (nombreArchivo) => { 
    try {
      get(`/archivosAdjuntos/${nombreArchivo}`, {
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${nombreArchivo}`); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };

  const eliminarArchivoAdjunto = async (e, i) => {
    const file = archivosAdjuntos[i]; 
    try {
      const rta = await del(`/api/notarial/cesiones/delete-file?cesionId=${props.cesionId}&fileId=${file._id}`);
      props.getCesion();
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };

  return (
    <>
      <div className="card-header">
        <h3 className="card-title">Archivos Adjuntos</h3>
        <input
          className="btn btn-primary float-right"
          type="button"
          value="Adjuntar Archivo"
          data-toggle="modal"
          data-target="#modal-archivos"
        />
      </div>

      {/* modal PARA ADJUNTAR ARCHIVOS*/}
      <div className="modal fade" id="modal-archivos">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Adjuntar Archivo</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <FormularioAdjuntarArchivo
                cesionId={props.cesionId}
                getCesion={props.getCesion}
              />
            </div>
          </div>
          {/* /.modal-content */}
        </div>
        {/* /.modal-dialog */}
      </div>
      {/* /.modal */}

      {archivosAdjuntos.map((archivo, i) => {
        return (
          <div className="row m-3" key={i}>
            <div className="form-group col-md-10">
              <label
                role="button"
                onClick={() => {
                  obtenerArchivo(archivo.nombreArchivo);
                }}
              >
                {archivo.nombre}
              </label>
            </div>
            <div className="form-group col-md-2">
              <input
                className="btn btn-danger "
                type="button"
                value="X"
                onClick={(e) => eliminarArchivoAdjunto(e, i)}
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

export default MostrarArchivosAdjuntos;
