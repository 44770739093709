import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import ShowPersona from '../../clientes/new/components/ShowPersona';
import Spinner from '../../layout/Spinner';
import { del, get } from '../../../config/apiHelper';
import MostrarComentarios from './components/MostrarComentarios';
import MostrarArchivosAdjuntos from './components/MostrarArchivosAdjuntos';
import Swal from 'sweetalert2';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';

const MostrarLotes = (props) => {
  const { lotes, nuevoEstado, nuevoMotivoDisponibilidad } = props;

  const columns = [
    {
      name: 'Barrio',
      selector: (row) => row.barrio.nombre,
      sortable: true,
    },
    {
      name: 'Lote',
      selector: (row) => row.numeroLote,
      sortable: true,
    },
    {
      name: 'Manzana',
      selector: (row) => row.numeroManzana,
      sortable: true,
    },
    {
      name: 'Superficie',
      selector: (row) => row.superficie,
      sortable: true,
    },
    {
      name: 'Nuevo Estado',
      selector: () => nuevoEstado?.estado || '-',
    },
    {
      name: 'Nuevo Motivo de Disponibilidad',
      selector: () => nuevoMotivoDisponibilidad?.nombre || '-',
    },
    {
      name: 'Ver',
      button: true,
      cell: (row) => (
        <>
          <button type='button' className="btn btn-primary" onClick={() => { props.history.push(`/lotes/ver/${row._id}`)}}>Ver</button>
        </>
      )
    }
  ]

  return (
    <>
      <label>Lotes</label>
      <DataTable
        columns={columns}
        data={lotes}
      />
    </>
  )
}

const MostrarComprador = (props) => {
  const { persona } = props;
  return (
    <>
      <label>Datos del Comprador</label>
      <ShowPersona client={persona} />
    </>
  )
}

const MostrarVendedor = (props) => {
  const { persona } = props;
  return (
    <>
      <label>Datos del Vendedor</label>
      <ShowPersona client={persona} />
    </>
  )
}

const MostrarEscribania = (props) => {
  const { escribania } = props;
  return (
    <>
      <label>Escribanía</label>
      <p>{escribania?.nombre || '-'}</p>
    </>
  )
}

const MostrarFechaYTipo = (props) => {
  const { fecha, tipo } = props;
  return (
    <>
      <div className="row mb-4">
        <div className="col-md-6">
          <label>Fecha de alta de Cesión</label>
          <p>{new Date(fecha).toLocaleDateString()}</p>
        </div>
        <div className="col-md-6">
          <label>Tipo de Cesión</label>
          <p>{tipo}</p>
        </div>
      </div>


    </>
  )
}

const MostrarCesion = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [cesion, setCesion] = useState({});

  const { id } = props.match.params;

  const getCesion = async () => {
    try {
      const response = await get(`/api/notarial/cesiones/${id}`);
      console.log(response);
      setCesion(response);
    } catch (error) {
      console.log(error);
    }
  }

  const cancelarCesion = () => {
    try {
      Swal.fire({
        title: 'Desea cancelar la Cesión?',
        text: "Esta acción no se puede revertir!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Si, cancelar!',
        cancelButtonText: 'Deshacer',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await del(`/api/notarial/cesiones/delete-cesion/${id}`);
            props.history.push('/listadoCesiones');
          } catch (error) {
            console.log(error);
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Hubo un error, intente nuevamente',
            });
          }
        }
      });
    } catch (error) {
      
    }
  }

  useEffect(() => {
    getCesion()
      .then(() => setIsLoading(false));
  }, [])

  if (isLoading) {
    return <Spinner />
  }

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title mt-2">Formulario de Cesión</h3>
        <button className=" ml-3 btn btn-secondary float-right" onClick={() => window.history.back()}>Volver</button>
        <button className=" ml-3 btn btn-danger float-right" onClick={cancelarCesion} disabled={cesion?.estado === 'Cancelada'}>Cancelar</button>
      </div>
      <div className="card-body">
        <div className="row mb-4">
          <div className="col-md-12">
            <MostrarFechaYTipo fecha={cesion.fechaAlta} tipo={cesion.tipo} />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-12">
            <MostrarLotes lotes={cesion.lotes} nuevoEstado={cesion.nuevoEstado} nuevoMotivoDisponibilidad={cesion.nuevoMotivoDisponibilidad} history={props.history} />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-6">
            <MostrarVendedor persona={cesion.vendedor} />
          </div>
          <div className="col-md-6">
            <MostrarComprador persona={cesion.comprador} />
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-md-12">
            <MostrarEscribania escribania={cesion.escribania} />
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-md-12">
            <label>Comentarios</label>
            <MostrarComentarios comentarios={cesion.comentarios} cesionId={id} getCesion={getCesion} />
          </div>
        </div>

        <div className="row-mb-4">
          <div className="col-md-12">
            <MostrarArchivosAdjuntos
              archivosAdjuntos={cesion.archivosAdjuntos}
              cesionId={id}
              getCesion={getCesion}
            />
          </div>
        </div>



      </div>
      <div className="card-footer"></div>
    </div>
  )
}

export default withRouter( MostrarCesion )