import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';

import { get } from "../../../config/apiHelper";
import Spinner from "../../layout/Spinner";
import listadoCesionesStore from "../../../store/listadoCesionesStore";
import Select from "react-select";


const ListadoDeCesiones = (props) => {

  const [cesiones, setCesiones] = useState([]);
  const [loading, setLoading] = useState(true);
  const [escribanias, setEscribanias] = useState([]);
  const [clientes, setClientes] = useState([]);
  const { filter, setFilter } = listadoCesionesStore()

  const tiposCesiones = ['Acuerdo Formal', 'Acuerdo entre Partes', 'Cesión de Boleto', 'Cesión entre Partes', 'Cesión', 'Boleto Formal', 'Boleto entre Partes'];

  const getCesiones = async () => {
    try {
      setLoading(true);
      let url = '/api/notarial/cesiones';
      if (filter) {
        url += '?';
        for (const key in filter) {
          if (filter[key]) {
            url += `${key}=${filter[key]}&`
          }
        }
      }
      const response = await get(url);
      setCesiones(response);
    } catch (error) {
      console.log("Error fetching cesiones:", error);
    } finally {
      setLoading(false);
    }
  }

  const columns = [
    {
      name: 'Fecha',
      selector: (row) => new Date(row.fechaAlta).toLocaleDateString(),
      sortable: true,
      sortFunction: (a, b) => new Date(a.fechaAlta) - new Date(b.fechaAlta),
    },
    {
      name: 'Vendedor',
      selector: (row) => row.vendedor?.razonSocial ? row.vendedor.razonSocial : `${row.vendedor?.nombre?.apellido}, ${row.vendedor?.nombre?.nombre}`,
      sortable: true,
    },
    {
      name: 'Comprador',
      selector: (row) => row.comprador?.razonSocial ? row.comprador.razonSocial : `${row.comprador?.nombre?.apellido}, ${row.comprador?.nombre?.nombre}`,
      sortable: true,
    },
    {
      name: 'Lotes',
      selector: (row) => row.lotes.map(lote => `${lote.barrio.nombre} - ${lote.numeroLote}`).join(', '),
      sortable: true,
    },
    {
      name: 'Tipo',
      selector: (row) => row.tipo,
      sortable: true,
    },
    {
      name: 'Escribanía',
      selector: (row) => row.escribania?.nombre,
      sortable: true,
    },
    {
      name: 'Estado',
      selector: (row) => row.estado,
      sortable: true,
    },
    {
      name: 'Acciones',
      cell: (row) => (
        <button className='btn btn-primary' onClick={() => props.history.push(`/listadoCesiones/${row._id}`)}>Ver</button>
      ),
    },
  ]

  const getEscribanias = async () => {
    try {
      const response = await get('/escribania');
      setEscribanias(response.data.sort((a, b) => a.nombre.localeCompare(b.nombre)));
    } catch (error) {
      console.log("Error fetching escribanias:", error);
    }
  }

  const getClientes = async () => {
    try {
      const response = await get('/clientes');
      setClientes(response.data);
    } catch (error) {
      console.log("Error fetching clientes:", error);
    }
  }



  useEffect(() => {
    getClientes();
    getEscribanias();
    getCesiones();
  }, []);

  useEffect(() => {
    getCesiones();
  }, [filter]);


  if(loading) {
    return <Spinner />
  }

  return (
    <div >
      <div className="card">
        <div className="card-header">
          <h3 className="card-title mt-2">Listado de Cesiones</h3>
          <button className="btn btn-primary float-right" onClick={() => props.history.push('/altaCesion')}>Nueva</button>
        </div>
        <div className="card-body">
          <div className="row mb-3">
            <div className="col-md-12">
              <label>Filtros</label>
              <div className="row mb-3">
                <div className="col-md-4">
                  <label>Vendedor</label>
                  <Select options={[{ value: '', label: 'Todos' }, ...clientes.map(cliente => ({ value: cliente._id, label: cliente.razonSocial || cliente.nombre.apellido + ' ' + cliente.nombre.nombre }))]} onChange={(selectedOption) => setFilter({ ...filter, vendedor: selectedOption.value })} value={clientes.find(cliente => cliente._id === filter.vendedor) ? { value: filter.vendedor, label: clientes.find(cliente => cliente._id === filter.vendedor).razonSocial || clientes.find(cliente => cliente._id === filter.vendedor).nombre.apellido + ' ' + clientes.find(cliente => cliente._id === filter.vendedor).nombre.nombre } : null} />
                </div>
                <div className="col-md-4">
                  <label>Comprador</label>
                  <Select options={[{ value: '', label: 'Todos' }, ...clientes.map(cliente => ({ value: cliente._id, label: cliente.razonSocial || cliente.nombre.apellido + ' ' + cliente.nombre.nombre }))]} onChange={(selectedOption) => setFilter({ ...filter, comprador: selectedOption.value })} value={clientes.find(cliente => cliente._id === filter.comprador) ? { value: filter.comprador, label: clientes.find(cliente => cliente._id === filter.comprador).razonSocial || clientes.find(cliente => cliente._id === filter.comprador).nombre.apellido + ' ' + clientes.find(cliente => cliente._id === filter.comprador).nombre.nombre } : null} />
                </div>
                <div className="col-md-4">
                  <label>Escribanía</label>
                  <Select options={[{ value: '', label: 'Todas' }, ...escribanias.map(escribania => ({ value: escribania._id, label: escribania.nombre }))]} onChange={(selectedOption) => setFilter({ ...filter, escribania: selectedOption.value })} value={escribanias.find(escribania => escribania._id === filter.escribania) ? { value: filter.escribania, label: escribanias.find(escribania => escribania._id === filter.escribania).nombre } : null} />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-4">
                  <label>Fecha Desde</label>
                  <input type="date" className="form-control" onChange={(e) => setFilter({ ...filter, fechaAltaDesde: e.target.value })} value={filter.fechaAltaDesde} />
                </div>
                <div className="col-md-4">
                  <label>Fecha Hasta</label>
                  <input type="date" className="form-control" onChange={(e) => setFilter({ ...filter, fechaAltaHasta: e.target.value })} value={filter.fechaAltaHasta} />
                </div>
                <div className="col-md-4">
                  <label>Tipo de Cesión</label>
                  <Select options={[{ value: '', label: 'Todos' }, ...tiposCesiones.map(tipo => ({ value: tipo, label: tipo }))]} onChange={(selectedOption) => setFilter({ ...filter, tipoCesion: selectedOption.value })} value={tiposCesiones.find(tipo => tipo === filter.tipoCesion)} />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-12 text-right">
                  <button className="btn btn-secondary mr-2" onClick={() => setFilter({})}>Limpiar Filtros</button>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              <label>Cesiones</label>
              <DataTable
                columns={columns}
                data={cesiones}
                pagination
                selectableRows
              />
            </div>
          </div>
        </div>
        <div className="card-footer"></div>
      </div>
    </div>
  );
};

export default ListadoDeCesiones;
