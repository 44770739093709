import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { post } from '../../../../config/apiHelper';

const FormularioAdjuntarArchivo = (props) => {

  const [archivo, setArchivo] = useState(null);
  // const [nombre, setNombre] = useState('');
  // const [validateFileName, setValidateFileName] = useState(false);


  // const actualizarNombre = e => {
  //   setValidateFileName(e.target.value.length > 0);
  //   setNombre(e.target.value);
  // }
  const actualizarArchivo = e => {
    setArchivo(e.target.files[0]);
  }

  const enviarArchivoAdjunto = async () => {

    const datosEnviar = new FormData();
    // datosEnviar.append('nombre', nombre);
    datosEnviar.append('archivoAdjunto', archivo);


    try {
      const rta = await post(`/api/notarial/cesiones/${props.cesionId}/upload`, datosEnviar, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (rta.estado == "ok") {
        Swal.fire("¡Correcto!", rta.msg, "success");
      } else if (rta.estado == "error") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: rta.msg,
        });
      }
      props.getCesion();
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }

  }

  return (
    <>
      {/* <div className="row">
        <div className="col form-group">
          <label>Nombre del Archivo:</label>
          <input type="text" name="nombre" onChange={actualizarNombre} required className="form-control" />
        </div>
      </div> */}
      <div className="row">
        <div className="col form-group">
          <label>Adjuntar:</label>
          <input type="file" name="archivoAdjunto" id="archivoAdjunto" onChange={actualizarArchivo} className="form-control" />
        </div>
      </div>

      <div className="modal-footer justify-content-between">
        <button type="button" className="btn btn-default" data-dismiss="modal">Cancelar</button>
        <button type="button" className="btn btn-primary" onClick={enviarArchivoAdjunto} data-dismiss="modal">Enviar</button>
      </div>
    </>
  )
}

export default FormularioAdjuntarArchivo;