import { React, useContext, useState } from "react";
import NumberFormat from "react-number-format";
import moment from "moment";
import { SelectTipoDni } from "./SelectTipoDni";
import { SelectNacionalidad } from "./SelectNacionalidad";
import { SelectEstadoCivil } from "./SelectEstadoCivil";
import { SelectInmobiliaria } from "./SelectInmobiliaria";
import DatosConyuge from "./DatosConyuge";
import SelectNupcias from "./SelectNupcias";
import { CRMContext } from "../../../context/CRMContext";
import SelectProvinciaMunicipio from "../../../shared/SelectProvinciaMunicipio";
import { GetPersonaByCuil } from "../../utils/getPersonaByCuil";
import Spinner from "../../layout/Spinner";
import clienteAxios from "../../../config/axios";
import ShowProvinciaMunicipio from "../../../shared/ShowProvinciaMunicipio";

export const FormularioVerCliente = ({
  cliente,
  actualizarState,
  actualizarStateV2,
  isNew = false,
  guardarCliente,
  ...props
}) => {
  const [showSpinner, setShowSpinner] = useState(false)
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");

  const [existentRepresentante, setExistentRepresentante] = useState(false);
  const [showErrorRepresentante, setErrorRepresentante] = useState(false);
  const [errorMessageRepresentante, setErrorMessageRepresentante] = useState("Persona no encontrada");
  const [showSpinnerRepresentante, setShowSpinnerRepresentante] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Persona no encontrada");

  const actualizarRepresentante = (e) => {
    guardarCliente({
      ...cliente,
      representante: {
        ...cliente.representante,
        [e.target.name]: e.target.value
      }
    });
  }

  const getClientByTaxId = async (taxId) => {
    try {
      const clientesConsulta = await clienteAxios.get(`/clientes/cuitCuil/${taxId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (clientesConsulta.data.hasOwnProperty('activo') || clientesConsulta.data.hasOwnProperty('message')) {
        return clientesConsulta.data;
      } else {
        return null;
      }
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };

  const getClientInfoFromAfip = async (taxId) => {

    try {
      const rta = await clienteAxios.get(`/afip?id=${taxId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { cliente: personaApi } = rta.data.data;
      if (rta.data.data.errorMessage) {
        setErrorMessage(rta.data.data.errorMessage);
        return null
      } else {
        return personaApi;
      }
    } catch (e) {
      console.log(e);
    }
  }

  const getOrCreateRepresentante = async (e) => {
    setExistentRepresentante(false);
    setErrorRepresentante(false);
    guardarCliente({
      ...cliente,
      representante: null,
    });
    let representanteFound = false;
    const taxId = e.value;
    if (taxId.length < 11) {
      return;
    }
    setShowSpinnerRepresentante(true);

    let apiRepresentante = await getClientByTaxId(taxId);

    if (apiRepresentante && apiRepresentante.message !== 'Client not found') {
      apiRepresentante.telefonoMovil = apiRepresentante.telefono?.movil;
      apiRepresentante.telefonoFijo = apiRepresentante.telefono?.particular;
      guardarCliente({
        ...cliente,
        representante: apiRepresentante,
      });
      representanteFound = true;
      setExistentRepresentante(true);
    } else {
      apiRepresentante = await getClientInfoFromAfip(taxId);
      if (apiRepresentante && apiRepresentante.hasOwnProperty('documentos')) {
        guardarCliente({
          ...cliente,
          representante: apiRepresentante,
        });
        representanteFound = true;
      }
    }
    if (!representanteFound) {
      setErrorMessageRepresentante('Persona no encontrada');
      setErrorRepresentante(true);
    }
    setShowSpinnerRepresentante(false);
  }

  const checkActivo = e => {
    e.target.value = e.target.value == 'on' ? true : false;
    actualizarState(e);
  }


  const clearCliente = (client) => {
    Object.keys(client).forEach(key => {
      if (key === 'cuitCuil' || key === 'fechaAlta') {
        return;
      }
      client = {
        ...client,
        [key]: null
      }
    });
    return client;
  }

  const clearClienteParameter = () => {
    actualizarStateV2(clearCliente(cliente));
  }

  const getClientByCuil = (clientApi) => {
    let state = clearCliente({ ...cliente });
    if (clientApi) {
      const { nombre, documentos, domicilio, fechaNacimiento, razonSocial, fechaContratoSocial } = clientApi;
      if (nombre) {
        Object.keys(nombre).forEach(key => {
          state = {
            ...state,
            [key]: nombre[key]
          };
        });
      }
      if (razonSocial) {
        state = {
          ...state,
          razonSocial
        }
      }
      Object.keys(documentos).forEach(key => {
        state = {
          ...state,
          [key]: documentos[key]
        };
      });
      Object.keys(domicilio).forEach(key => {
        state = {
          ...state,
          [key]: domicilio[key]
        };
      });
      if (fechaNacimiento) {
        state = {
          ...state,
          fechaNacimiento,
        }
      } else {
        state = {
          ...state,
          fechaContratoSocial
        }
      }
      ;
      state = {
        ...state,
        createdFromAfip: true,
      }
      actualizarStateV2(state);
    }
  }

  const checkDisabledByRol = () => {
    const disabledEditionRol = ['1', '2'];
    const rolId = localStorage.getItem('rolId');
    return disabledEditionRol.includes(rolId);
  }

  return (
    <>
      {showSpinner &&
        <Spinner />
      }
      {(cliente.nombre || cliente.apellido) &&
        <div className="row">
          <div className="col-md-4 form-group">
            <label>Nombre *</label>
            <input
              type="text"
              className="form-control"
              placeholder="Nombre"
              name="nombre"
              value={cliente?.nombre}
              required
              disabled
            />
          </div>


          <div className="col-md-4 form-group">
            <label>Apellido *</label>
            <input
              type="text"
              className="form-control"
              placeholder="Apellido"
              name="apellido"
              value={cliente?.apellido}
              disabled
            />
          </div>

          <div className="col-md-4 form-group">
            <label>Apellido Materno</label>
            <input
              type="text"
              className="form-control"
              placeholder="Apellido Materno"
              name="apellidoMaterno"
              value={cliente?.apellidoMaterno}
              disabled
            />
          </div>
        </div>
      }
      {cliente.razonSocial &&
        <div className="row">
          <div className="col-md-12 form-group">
            <label>Razón Social *</label>
            <input
              type="text"
              className="form-control"
              placeholder="Razón Social"
              name="nombre"
              value={cliente?.razonSocial}
              disabled
            />
          </div>
        </div>
      }
      <div className="row">

        {cliente.tipoDni &&
          <div className="col-md-3 form-group">
            <label>DNI Tipo</label>
            <input
              type="text"
              className="form-control"
              placeholder="Razón Social"
              name="nombre"
              value={cliente?.tipoDni}
              disabled
            />
          </div>
        }

        {cliente.dni &&
          <div className="col-md-3 form-group">
            <label>Documento *</label>
            <NumberFormat
              className="form-control"
              name="dni"
              value={cliente?.dni}
              placeholder="20.123.456"
              format="##.###.###"
              mask="_"
              required
              disabled
            />
          </div>
        }

        {(cliente.nombre || cliente.apellido) &&
        <>
          <div className="col-md-3 form-group">
            <label>Nacionalidad</label>
            <input
              type="text"
              className="form-control"
              placeholder="Razón Social"
              name="nombre"
              value={cliente?.nacionalidad?.nombre}
              disabled
            />
          </div>
        </>
        }

        <div className="col-md-3 form-group">
          <label>CUIT / CUIL</label>
          <NumberFormat
            className="form-control"
            name="cuitCuil"
            value={cliente?.cuitCuil}
            placeholder="20-12345678-9"
            format="##-########-#"
            mask="_"
            disabled={true}
          />
        </div>
      </div>

      {cliente.calle &&
        <>
          <div className="form-group">
            <label>Domicilio</label>
          </div>

          <div className="row">
            <ShowProvinciaMunicipio
              provincia={cliente.provincia}
              municipio={cliente.municipio}
            />
            <div className="col-md-2 form-group">
              <label>Código Postal</label>
              <input
                type="text"
                className="form-control"
                placeholder="CP"
                name="cp"
                value={cliente?.cp}
                required
                disabled
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 form-group">
              <label>Calle *</label>
              <input
                type="text"
                className="form-control"
                placeholder="Calle"
                name="calle"
                value={cliente?.calle}
                required
                disabled
              />
            </div>

            <div className="col-md-3 form-group">
              <label>Altura *</label>
              <input
                type="text"
                className="form-control"
                placeholder="Altura"
                name="altura"
                value={cliente?.altura}
                required
                disabled
              />
            </div>
            <div className="col-md-3 form-group">
              <label>Piso / Depto</label>
              <input
                type="text"
                className="form-control"
                placeholder="Piso / Depto"
                name="pisoDepto"
                value={cliente?.pisoDepto}
                disabled
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 form-group">
              <label>Teléfono Móvil * (Sin 0 y sin 15)</label>
              <NumberFormat
                className="form-control"
                placeholder="__________"
                name="telefonoMovil"
                format="##########"
                mask="_"
                disabled
                value={cliente?.telefonoMovil || ''}
                required
              />
            </div>
            <div className="col-md-6 form-group">
              <label>Teléfono Fijo (Sin 0)</label>
              <NumberFormat
                className="form-control"
                name="telefonoFijo"
                format="##########"
                placeholder="__________"
                mask="_"
                disabled
                value={cliente?.telefonoFijo || ''}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-8 form-group">
              <label>Email *</label>
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                name="email"
                value={cliente?.email}
                disabled
              />
            </div>
            {cliente.fechaNacimiento &&
              <div className="col-md-4 form-group">
                <label>Fecha de Nacimiento *</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Fecha de Nacimiento"
                  name="fechaNacimiento"
                  value={moment(cliente?.fechaNacimiento)
                    .utcOffset(180)
                    .format("YYYY-MM-DD")}
                  disabled
                />
              </div>
            }
            {cliente.fechaContratoSocial &&
              <div className="col-md-4 form-group">
                <label>Alta de Contrato Social *</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Alta de Contrato Social"
                  name="fechaAlta"
                  value={moment(cliente?.fechaContratoSocial)
                    .utcOffset(180)
                    .format("YYYY-MM-DD")}
                  disabled
                />
              </div>
            }
          </div>
        </>
      }
      {(cliente.nombre || cliente.apellido) &&
        <>
        <div className="row">
          <div className="col-md-6 form-group">
            <label>Estado Civil *</label>
            <input
              type="text"
              className="form-control"
              value={cliente?.estadoCivil?.nombre}
              disabled
            />
          </div>
        </div>
        {cliente?.nupcias ? 
          <div className="row">
            <div className="col-md-6 form-group">
              <label>Cantidad de Nupcias</label>
              <input
                type="text"
                className="form-control"
                value={cliente?.nupcias}
                disabled
              />
            </div>
          </div>
        : null}
          <DatosConyuge cliente={cliente} actualizarState={actualizarStateV2} />
        </>
      }
      {cliente.razonSocial &&
        <>
          <div className="row">
            <label>
              Información del representante:
            </label>
          </div>
        </>
      }
      {(cliente.representante?.nombre || cliente.representante?.apellido) &&
        <div className="row">
          <div className="col-md-4 form-group">
            <label>Nombre *</label>
            <input
              type="text"
              className="form-control"
              placeholder="Nombre"
              name="nombre"
              value={cliente.representante?.nombre.nombre}
              disabled
            />
          </div>


          <div className="col-md-4 form-group">
            <label>Apellido *</label>
            <input
              type="text"
              className="form-control"
              placeholder="Apellido"
              name="apellido"
              value={cliente.representante?.nombre.apellido}
              disabled
            />
          </div>

          {cliente.representante?.fechaNacimiento &&
            <div className="col-md-4 form-group">
              <label>Fecha de Nacimiento *</label>
              <input
                type="date"
                className="form-control"
                placeholder="Fecha de Nacimiento"
                name="fechaNacimiento"
                value={moment(cliente.representante?.fechaNacimiento)
                  .utcOffset(180)
                  .format("YYYY-MM-DD")}
                required
                disabled
              />
            </div>
          }
        </div>
      }
      {cliente.representante?.domicilio &&
        <>
          <div className="form-group">
            <label>Domicilio</label>
          </div>

          <div className="row">
            <div className="col-md-5 form-group">
              <label>Provincia *</label>
              <input
                type="text"
                className="form-control"
                placeholder="Provincia"
                name="provincia"
                disabled
                value={cliente.representante?.domicilio.provincia}
              />
            </div>

            <div className="col-md-5 form-group">
              <label>Municipio *</label>
              <input
                type="text"
                className="form-control"
                placeholder="Municipio"
                name="municipio"
                disabled
                value={cliente.representante?.domicilio.municipio}
              />
            </div>
            <div className="col-md-2 form-group">
              <label>Código Postal</label>
              <input
                type="text"
                className="form-control"
                placeholder="CP"
                name="cp"
                value={cliente.representante?.domicilio.cp}
                disabled
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 form-group">
              <label>Calle *</label>
              <input
                type="text"
                className="form-control"
                placeholder="Calle"
                name="calle"
                value={cliente.representante?.domicilio.calle}
                disabled
              />
            </div>

            <div className="col-md-3 form-group">
              <label>Altura *</label>
              <input
                type="text"
                className="form-control"
                placeholder="Altura"
                name="altura"
                value={cliente.representante?.domicilio.altura}
                disabled
              />
            </div>
            <div className="col-md-3 form-group">
              <label>Piso / Depto</label>
              <input
                type="text"
                className="form-control"
                placeholder="Piso / Depto"
                name="pisoDepto"
                value={cliente.representante?.domicilio.pisoDepto}
                disabled
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 form-group">
              <label>Teléfono Móvil * (Sin 0 y sin 15)</label>
              <NumberFormat
                className="form-control"
                placeholder="__________"
                name="telefonoMovil"
                format="##########"
                mask="_"
                value={cliente?.representante.telefonoMovil || ''}
                readOnly
              />
            </div>
            <div className="col-md-4 form-group">
              <label>Teléfono Fijo (Sin 0)</label>
              <NumberFormat
                className="form-control"
                name="telefonoFijo"
                format="##########"
                placeholder="__________"
                mask="_"
                value={cliente?.representante.telefonoFijo || ''}
                readOnly
              />
            </div>
            {cliente && cliente.nombre &&
              <div className="col-md-4 form-group">
                <label>Apellido Materno *</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Apellido Materno"
                  name="apellidoMaterno"
                  value={cliente?.representante.nombre.apellidoMaterno}
                  disabled
                />
              </div>
            }
          </div>

          <div className="row">
            <div className="col-md-8 form-group">
              <label>Email *</label>
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                name="email"
                value={cliente.representante?.email}
                required
                disabled
              />
            </div>
            {(cliente.representante?.nombre || cliente.representante?.apellido) &&
              <SelectNacionalidad
                actualizarState={actualizarRepresentante}
                cliente={cliente.representante}
                token={token}
                required
                disabled
              />
            }
          </div>
        </>
      }

      <div className="row">
        <div className="text-center col-md-6 form-group">
          <label>Inmobiliaria</label>
          <input
            type="text"
            className="form-control"
            value={cliente?.inmobiliaria?.nombre}
            disabled
          />
        </div>
        {!isNew &&
          <div className="text-center col-md-6 form-group">
            <label>Activo</label>
            <input
              type="checkbox"
              className="form-control"
              name="activo"
              checked={cliente?.activo}
              disabled
            />
          </div>
        }
      </div>
    </>
  );
};
