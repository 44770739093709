import React, { useState } from 'react'
import ReactJson from 'react-json-view';

import { getClientByCuix, getPersonaByCuix } from './utils'

import ShowPersona from './components/ShowPersona';

const ShowExistentClient = () => {

  const [fromAfip, setFromAfip] = useState({})
  const [fromCliente, setFromCliente] = useState({})

  const getClient = async (e) => {
    const {value: id} = e.target;
    if(!id || id.length < 11) {
      setFromCliente({});  
      return
    };
    const client = await getClientByCuix(id);
    console.log('getClient', client);
    setFromCliente(client);
  }
  
  const getFromAfip = async (e) => {
    const {value: id} = e.target;
    if(!id || id.length < 11) {
      setFromAfip({});  
      return
    };
    const client = await getPersonaByCuix(id);
    console.log('getFromAfip', client);
    setFromAfip(client);
  }



  return (
    <>
    <div className="card">
      <div className="card-header"></div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-3">
            <label>GetClient</label>
            <input className='form-control' type="text"  onChange={getClient}/>
          </div>
        </div>
        <ReactJson src={fromCliente} />
        <ShowPersona
          client={fromCliente}
        />
        <label>GetFromAfip</label>
        <input className='form-control' type="text"  onChange={getFromAfip}/>
        <ReactJson src={fromAfip} />
        <ShowPersona
          client={fromAfip}
        />
      </div>
      <div className="card-footer"></div>
    </div>
      
    </>
  )
}

export default ShowExistentClient