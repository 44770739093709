import React, { useEffect, useState, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import DataTable from "react-data-table-component";
import clienteAxios from "../../config/axios";
import { CRMContext } from "../../context/CRMContext";
import Spinner from "../layout/Spinner";

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import ClientesFiltros from "./utils/filter";

function ListadoClientes(props) {
  const [clientes, guardarClientes] = useState([]);
  const [clientesOriginal, setClientesOriginal] = useState({
    isLoading: true,
    listado: []
  });
  const [query,setQuery] = useState('');
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");
  let paginator = {
    page: 1,
    limit: 10
  };
  const [totalRows, setTotalRows] = useState(0);
  const nivelRol = localStorage.getItem('rolId');

  const consultarAPI = async () => {
    try {
      const clientesConsulta = await clienteAxios(`/clientes?campos=razonSocial,datosConyuge,nombre,documentos,email,inmobiliaria&page=${paginator.page - 1}&limit=${paginator.limit}&sort=nombre.apellido,1&${query}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });


      const arrayOriginal = clientesConsulta.data.data.map((cliente) => {
        const nombreCompleto = cliente.nombre.apellido ? `${cliente.nombre.apellido}, ${cliente.nombre.nombre} ${cliente.nombre.segundoNombre || ''}` : cliente.razonSocial;
        return {
          nombreCompleto,
          numeroCuilCuit: cliente.documentos.cuitCuil,
          email: cliente.email,
          inmobiliaria: cliente.inmobiliaria?.nombre,
          url: `/clientes/editar/${cliente._id}`,
          ver: `/clientes/ver/${cliente._id}`,
        };
      });
      setTotalRows(clientesConsulta.data.totalCount);

      guardarClientes(arrayOriginal);
      setClientesOriginal({
        isLoading: false,
        listado: arrayOriginal
      });
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };


  useEffect(() => {

    consultarAPI();
  }, [query]);

  // Datatable
  const columns = [
    {
      name: "Nombre Completo",
      // width: '20%',
      selector: (row) => row.nombreCompleto,
      sortable: true,
    },
    {
      name: "CUIT / CUIL",
      // width: '20%',
      selector: (row) => row.numeroCuilCuit,
      sortable: true,
    },
    {
      name: "Email",
      // width: '20%',
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Inmobiliaria",
      // width: '20%',
      selector: (row) => row.inmobiliaria,
      sortable: true,
    },{
      name: 'Ver',
      button: true,
      // width: '10%',
      cell: (row) => (
        <Link to={row.ver}>
        <button type="button" className="btn btn-block btn-success">
          Ver
        </button>
      </Link>
      ),
    },{
      name: 'Editar',
      button: true,
      // width: '10%',
      cell: (row) => (
        <Link to={row.url}>
        <button type="button" className="btn btn-block btn-success">
          Editar
        </button>
      </Link>
      ),
    }
  ];

  const data = clientes.map( cliente => {
    return {
      "Nombre Completo": cliente.nombreCompleto,
      "CUIT / CUIL": cliente.numeroCuilCuit,
      "Email": cliente.email,
      "Inmobiliaria": cliente.inmobiliaria,
    }
  })

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, 'data.xlsx');
  };

  const handlePerRowsChange = async (limit) => {
    paginator = {
      ...paginator,
      limit,
    };
    consultarAPI();
  }

  const handlePageChange = async (page) => {
    paginator = {
      ...paginator,
      page,
    };
    consultarAPI();
  }

  // Configuracion del datatable
  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  if (clientesOriginal.isLoading) {
    return <Spinner />;
  }


  return (
    <div className="card">
      <div className="card-header">
        <div className="row">
          <div className="col-md-8">
            <h3 className="card-title">Clientes</h3>
          </div>
          <div className="col-md-2">
            <button className="btn btn-block btn-success mb-2" onClick={exportToExcel}>Exportar</button>
          </div>
          <div className="col-md-2">
            <Link to={`/clientes/nuevo`}>
              <button type="button" className="btn btn-block btn-success">
                Nuevo Cliente
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="card-body">
        <ClientesFiltros
          setQuery={setQuery}
        />
        <DataTable
          columns={columns}
          data={clientes}
          pagination
          paginationServer
          paginationComponentOptions={paginationComponentOptions}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationTotalRows={totalRows}
          paginationDefaultPage={paginator.page}
          paginationDefaultRowsPerPage={paginator.limit}
        />
      </div>
    </div>
  );
}

export default withRouter(ListadoClientes);
