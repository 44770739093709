import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import Spinner from '../../layout/Spinner';
import { get, post } from '../../../config/apiHelper';
import DataTable from 'react-data-table-component';
import NumberFormat from 'react-number-format';
import { getClientByCuix, getPersonaByCuix } from '../../clientes/new/utils';
import ShowPersona from '../../clientes/new/components/ShowPersona';

const SelectDesarrollos = (props) => {
  const [fullyLoaded, setFullyLoaded] = useState(false);
  const [desarrollos, setDesarrollos] = useState([]);

  const { setSelectedDesarrollo } = props;

  const getDesarrollos = async () => {
    try {
      const response = await get('/barrios');
      setDesarrollos(response);
    } catch (error) {
      console.log('Error fetching desarrollos:', error);
    }
  }

  useEffect(() => {
    getDesarrollos()
      .then(() => {
        setFullyLoaded(true)
      });
  }, [])

  return !fullyLoaded ? <Spinner /> : (
    <>
      <label>Desarrollo Seleccionado:</label>
      <Select
        options={desarrollos.map((desarrollo) => ({ value: desarrollo._id, label: desarrollo.nombre }))}
        onChange={(selected) => setSelectedDesarrollo(selected.value)}
        placeholder='Seleccione un desarrollo'
      />
    </>
  )
}

const SelectLotes = (props) => {
  const { desarrolloId, setLotesSeleccionados, setNuevoEstado, setNuevoMotivo } = props;
  const [fullyLoaded, setFullyLoaded] = useState(false);
  const [lotes, setLotes] = useState([]);
  const [selectedLotes, setSelectedLotes] = useState([]);
  const [estados, setEstados] = useState([]);
  const [motivoDisponibilidad, setMotivoDisponibilidad] = useState([]);

  const getLotes = async (desarrolloId) => {
    try {
      const response = await get(`/lotes?barrio=${desarrolloId}&sort=numeroLote,1`);
      setLotes(response.data);
    } catch (error) {
      console.log('Error fetching lotes:', error);
    }
  }

  const getEstados = async () => {
    try {
      const response = await get('/estadosLotes');
      setEstados(response);
    } catch (error) {
      console.log('Error fetching estados:', error);
    }
  }

  const getMotivos = async () => {
    try {
      const response = await get('/motivoDisponibilidad');
      setMotivoDisponibilidad(response.data);
    } catch (error) {
      console.log('Error fetching motivos:', error);
    }
  }

  useEffect(() => {
    setFullyLoaded(false);
    Promise.all([
      getLotes(desarrolloId),
      getEstados(),
      getMotivos()
    ]).then(() => {
      setFullyLoaded(true)
    });
  }, [desarrolloId])

  useEffect(() => {
    setLotesSeleccionados(selectedLotes.map(lote => lote.value));
  }, [selectedLotes])

  const handleDeleteLote = (loteId) => {
    setSelectedLotes(selectedLotes.filter(lote => lote.value !== loteId));
  };

  const columns = [
    {
      name: 'Desarrollo',
      selector: (row) => row.barrio,
      sortable: true,

    },
    {
      name: 'Lote',
      selector: (row) => row.label,
      sortable: true,
    },
    {
      cell: (row) => (
        <button className='btn btn-danger' onClick={() => handleDeleteLote(row.value)}>X</button>
      ),
    },
  ];

  return !fullyLoaded ? <Spinner /> : (
    <>
      <label>Seleccionar Lotes:</label>
      <Select
        options={lotes.map((lote) => ({ value: lote._id, label: `Lote ${lote.numeroLote} | Estado: ${lote.estado.estado} | Motivo: ${lote.motivoDisponibilidad?.nombre || ''}`, barrio: lote.barrio?.nombre }))}
        onChange={(selected) => setSelectedLotes([...selectedLotes, selected])}
        placeholder='Seleccione un lote'
        className='mb-3'
      />
      <label>Lotes Seleccionados</label>
      <DataTable
        columns={columns}
        data={selectedLotes}
      />

      <label className='mt-3'>Nuevo Estado de los lotes:</label>
      <div className="row mb-3 mt-3">
        <div className="col-md-6">
          <label>Estado:</label>
          <Select
            className='fixed-select'
            options={estados.map((estado) => ({ value: estado._id, label: estado.estado }))}
            placeholder='Seleccione un estado'
            onChange={(selected) => setNuevoEstado(selected.value)}
          />
        </div>
        <div className="col-md-6">
          <label>Motivo de disponibilidad</label>
          <Select
            className='fixed-select'
            options={motivoDisponibilidad.map((motivo) => ({ value: motivo._id, label: motivo.nombre }))}
            placeholder='Seleccione un motivo'
            onChange={(selected) => setNuevoMotivo(selected.value)}
          />
        </div>
      </div>



    </>
  )
}

const SelectPersona = (props) => {
  const { setterPersona, parte = '' } = props;
  const [persona, setPersona] = useState(null);
  const [personaCuitCuil, setPersonaCuitCuil] = useState('');

  const getPersona = async (id) => {
    const personaFromClients = await getClientByCuix(id);
    if (personaFromClients && personaFromClients._id) {
      setPersona(personaFromClients);
      setterPersona(personaFromClients);
      return;
    } else {
      const personaFromAfip = await getPersonaByCuix(id);
      if (personaFromAfip) {
        setPersona(personaFromAfip);
        setterPersona(personaFromAfip);
        return;
      }
    }
  }

  useEffect(() => {
    if (personaCuitCuil.length === 11) {
      getPersona(personaCuitCuil);
    } else {
      setPersona(null);
      setterPersona(null);
    }
  }, [personaCuitCuil])

  return (
    <>
      <label>{`CUIT/CUIL del ${parte}`}</label>
      <NumberFormat
        type="text"
        className="form-control mb-3"
        name="cuilCuit"
        placeholder="20-12345678-9"
        format="##-########-#"
        mask="_"
        required
        onValueChange={(e) => { setPersonaCuitCuil(e.value) }}
      />
      {persona && Object.keys(persona).length > 0 ?
        <ShowPersona client={persona} setClient={setterPersona} /> :
        null
      }
    </>
  )
}

const SelectTipoOperacion = (props) => {
  const { setterTipoOperacion } = props;
  const tiposCesiones = ['Acuerdo Formal', 'Acuerdo entre Partes', 'Cesión de Boleto', 'Cesión entre Partes', 'Cesión', 'Boleto Formal', 'Boleto entre Partes'];

  return (
    <>
      <label>Tipo de Operación:</label>
      <Select
        options={tiposCesiones.map((tipo) => ({ value: tipo, label: tipo }))}
        onChange={(selected) => setterTipoOperacion(selected.value)}
        placeholder='Seleccione un tipo de operación'
      />
    </>
  )
}

const AddComentarios = (props) => {
  const { setComentarios } = props;


  return (
    <>
      <label htmlFor="comentarios">Comentarios</label>
      <textarea
        className="form-control"
        id="comentarios"
        rows="3"
        onChange={(e) => setComentarios([`${e.target.value}`])}
      />
    </>
  )
}

const AddFiles = (props) => {
  const { setFiles } = props;
  const handleFileChange = (event) => {
    setFiles(Array.from(event.target.files)); // Guardar los archivos seleccionados en el estado
  };


  return (
    <>
      <label>Adjuntar Archivos:</label>
      <input
        type="file"
        multiple
        className="form-control"
        onChange={handleFileChange}
      />
    </>
  )
}

const SelectEscribania = (props) => {
  const { setEscribania } = props;
  const [escribanias, setEscribanias] = useState([]);

  const getEscribanias = async () => {
    try {
      const { data } = await get('/escribania');
      data.unshift({ _id: '', nombre: '-- Ninguna --' });
      setEscribanias(data);
    } catch (error) {
      console.log('Error fetching escribanias:', error);
    }
  }

  useEffect(() => {
    getEscribanias();
  }, [])

  return (
    <>
      <label>Escribania:</label>
      <Select
        options={escribanias.map((escribania) => ({ value: escribania._id, label: escribania.nombre })).sort((a, b) => a.label.localeCompare(b.label))}
        placeholder='Seleccione una escribania'
        onChange={(selected) => setEscribania(selected.value)}
      />
    </>
  );

}

const FormularioAlta = (props) => {

  const [selectedDesarrollo, setSelectedDesarrollo] = useState(null);
  const [selectedLotes, setSelectedLotes] = useState([]);
  const [comprador, setComprador] = useState({});
  const [vendedor, setVendedor] = useState({});
  const [tipoOperacion, setTipoOperacion] = useState('');
  const [comentarios, setComentarios] = useState([]);
  const [escribania, setEscribania] = useState(null);
  const [nuevoEstado, setNuevoEstado] = useState(null);
  const [nuevoMotivo, setNuevoMotivo] = useState(null);
  const [files, setFiles] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);

  const validForm = () => {

    return Array.isArray(selectedLotes) &&
      selectedLotes.length > 0 && 
      comprador !== null &&
      vendedor !== null &&
      !!tipoOperacion
  }

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('lotes', selectedLotes);
    formData.append('nuevoEstado', nuevoEstado);
    formData.append('nuevoMotivo', nuevoMotivo);
    formData.append('comprador', JSON.stringify(comprador));
    formData.append('vendedor', JSON.stringify(vendedor));
    formData.append('tipo', tipoOperacion);
    formData.append('comentarios', comentarios);
    formData.append('escribania', escribania);
    files.forEach((file, index) => {
      formData.append(`file_${index}`, file);
    });
    try {
      setShowSpinner(true);
      console.log(formData.keys().forEach(key => console.log(`${key} - ${formData.get(key)}`)));
      const response = await post('/api/notarial/cesiones', formData);
      console.log(response);
      setShowSpinner(false);
      props.history.push(`/listadoCesiones/${response._id}`);
    } catch (error) {
      console.log(error);
    } finally {
      setShowSpinner(false);
    }
  }

  const handleTabChange = (direction) => {
    const tabs = Array.from(document.querySelectorAll("#custom-tabs-two-tab .nav-link"));
    const activeTab = tabs.find((tab) => tab.classList.contains("active"));
    const currentIndex = tabs.indexOf(activeTab);

    let newIndex;
    if (direction === "next") {
      newIndex = (currentIndex + 1) % tabs.length;
    } else if (direction === "prev") {
      newIndex = (currentIndex - 1 + tabs.length) % tabs.length;
    }

    if (newIndex !== undefined) {
      tabs[currentIndex].classList.remove("active");
      tabs[newIndex].classList.add("active");

      const activeContent = document.querySelector(activeTab.getAttribute("href"));
      const newContent = document.querySelector(tabs[newIndex].getAttribute("href"));

      activeContent.classList.remove("show", "active");
      newContent.classList.add("show", "active");
    }
  };

  if (showSpinner) {
    return <Spinner />
  }


  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title mt-2">Formulario de Cesión</h3>
        <button className="btn btn-secondary float-right" onClick={() => window.history.back()}>Volver</button>
      </div>
      <div className="card-body">

        <div className="row">
          <div className="col-md-12">
            <div className="card card-primary card-tabs">
              <div className="card-header p-0 pt-1">
                <ul className="nav nav-tabs" id="custom-tabs-two-tab" role="tablist">
                  <li className="pt-2 px-3">
                    <h3 className="card-title">Formulario de Cesión</h3>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="select-lotes-tab"
                      data-toggle="pill"
                      href="#select-lotes"
                      role="tab"
                      aria-controls="select-lotes"
                      aria-selected="true"
                    >
                      Lotes
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="select-persons-tab"
                      data-toggle="pill"
                      href="#select-persons"
                      role="tab"
                      aria-controls="select-persons"
                      aria-selected="false"
                    >
                      Vendedor / Comprador
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="select-info-cesion-tab"
                      data-toggle="pill"
                      href="#select-info-cesion"
                      role="tab"
                      aria-controls="select-info-cesion"
                      aria-selected="false"
                    >
                      Información de la Cesión
                    </a>
                  </li>
                </ul>
              </div>
              <div className="card-body">
                <div className="tab-content" id="custom-tabs-two-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="select-lotes"
                    role="tabpanel"
                    aria-labelledby="select-lotes-tab"
                  >
                    <div className="row mb-3">
                      <div className="col-md-3">
                        <SelectDesarrollos setSelectedDesarrollo={setSelectedDesarrollo} />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-9">
                        {selectedDesarrollo ? (
                          <SelectLotes
                            setNuevoEstado={setNuevoEstado}
                            setNuevoMotivo={setNuevoMotivo}
                            setLotesSeleccionados={setSelectedLotes}
                            desarrolloId={selectedDesarrollo}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="select-persons"
                    role="tabpanel"
                    aria-labelledby="select-persons-tab"
                  >
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <SelectPersona setterPersona={setVendedor} parte="Vendedor" />
                      </div>
                      <div className="col-md-6">
                        <SelectPersona setterPersona={setComprador} parte="Comprador" />
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="select-info-cesion"
                    role="tabpanel"
                    aria-labelledby="select-info-cesion-tab"
                  >
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <SelectTipoOperacion setterTipoOperacion={setTipoOperacion} />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-6">
                        <AddComentarios setComentarios={setComentarios} />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-6">
                        <AddFiles setFiles={setFiles} />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-6">
                        <SelectEscribania setEscribania={setEscribania} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-md-6">
                    <button
                      className="btn btn-secondary"
                      onClick={() => handleTabChange("prev")}
                    >
                      Anterior
                    </button>
                  </div>
                  <div className="col-md-6 text-right">
                    <button
                      className="btn btn-primary"
                      onClick={() => handleTabChange("next")}
                    >
                      Siguiente
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button className="btn btn-primary float-right" onClick={handleSubmit} disabled={!validForm()}>Crear Cesión</button>


      </div>
      <div className="card-footer"></div>
    </div>
  )
}

export default FormularioAlta