import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

import Swal from "sweetalert2";
import { post } from "../../../../config/apiHelper";

const MostrarComentarios = (props) => {

  const { comentarios, cesionId, getCesion } = props;

  const [nuevoComentario, setNuevoComentario] = useState('');

  const spliceComentario = (comentario) => {
    const lineas = comentario.split('\n');
    return lineas;
  }

  const agregarComentario = async () => {
    try {
      await post(`/api/notarial/cesiones/${cesionId}/comment`, { comentario: nuevoComentario });
      getCesion();
      setNuevoComentario('')
    } catch (error) {
      console.log(error); 
    }
  }


  return (
    <>
      <div className="row">
        <div className="col-md-12">
          {/* The time line */}
          <div className="timeline">
            {comentarios?.length > 0 ? comentarios.map((comentario, index) => {
              return (
                <div key={index}>
                  <i className="fas fa-envelope bg-blue" />
                  <div className="timeline-item">
                    <span className="time">
                      <i className="fas fa-clock" />{" "}
                      {moment(comentario.fecha)
                        //   .utcOffset(180)
                        .format("DD-MM-YYYY - HH:mm")}
                    </span>
                    <h3 className="timeline-header">
                      {`Usuario: ${comentario.usuario?.apellido || '< Sin Apellido >'}, ${comentario.usuario?.nombre || '< Sin Nombre >'}`} - {comentario.usuario?.inmobiliaria?.nombre || '< Sin Nombre Inmobiliaria>'}
                    </h3>
                    {spliceComentario(comentario.comentario).map((linea) => {
                      return <div className="timeline-body">{linea}</div>
                    })}
                  </div>
                </div>
              );
            }) : ''}
          </div>
          <div className="modal-body col-md-12">
            <p>Nuevo Comentario:</p>
            <textarea
              className="p-4 w-100"
              name="comentario"
              id=""
              cols="100"
              rows="3"
              onChange={(e) => setNuevoComentario(e.target.value)}
              value={nuevoComentario}
            ></textarea>
          </div>
          <input className="btn btn-primary float-right" onClick={agregarComentario} type="button" value="Enviar Comentario" />
        </div>
      </div>
    </>
  );
};

export default MostrarComentarios;
