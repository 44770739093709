import React from "react";

const ShowProvinciaMunicipio = ({ provincia, municipio }) => {



  return (
    <>
      <div className="col-md-5 form-group">
        <label>Provincia *</label>
        <input
          type="text"
          className="form-control"
          placeholder="Provincia"
          name="provincia"
          value={provincia}
          disabled
        />
      </div>

      <div className="col-md-5 form-group">
        <label>Municipio *</label>
        <input
          type="text"
          className="form-control"
          placeholder="Municipio"
          name="municipio"
          value={municipio}
          disabled
        />
      </div>

    </>
  );

}

export default ShowProvinciaMunicipio;