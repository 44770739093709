import React, { useState, useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Spinner from "../layout/Spinner";
import moment from "moment";
import { get } from "../../config/apiHelper";
import { FormularioVerCliente } from "./components/FormularioVerCliente";

const VerCliente = (props) => {
  const [cliente, guardarCliente] = useState({});
  const [operaciones, setOperaciones] = useState([]);
  const [cesiones, setCesiones] = useState([]);
  const nivelRol = localStorage.getItem('rolId');

  const getCesiones = async () => {
    try {
      const { id } = props.match.params;
      const response = await get(`/api/notarial/cesiones/client/${id}`);
      setCesiones(response);
    } catch (error) {
      console.log(error);
    }
  }
  const consultarCliente = async () => {
    const { id } = props.match.params;
    try {
      const clienteDB = await get(`/clientes/${id}`);
      guardarCliente(clienteDB2clientePlano(clienteDB));
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };

  // Get the client from the API
  useEffect(() => {
    getCesiones();
    consultarCliente();
    findOperacionesByClientId();
  }, []);

  // Update client data
  const actualizarState = (e) => {
    guardarCliente({
      ...cliente,
      [e.target.name]: e.target.value,
    });
  };


  // Function to convert the client obtained from the API into a flat object
  const clienteDB2clientePlano = (cliente) => {
    let rta = {
      _id: cliente._id,
      cuitCuil: cliente?.documentos?.cuitCuil,
      nombre: cliente.nombre.nombre,
      cuilConyuge: cliente.datosConyuge?.cuil,
      segundoNombre: cliente.nombre.segundoNombre,
      apellido: cliente.nombre.apellido,
      apellidoMaterno: cliente.nombre.apellidoMaterno,
      dni: cliente.documentos.dni,
      tipoDni: cliente.documentos.dniTipo,
      numeroCuilCuit: cliente.documentos.cuitCuil,
      provincia: cliente.domicilio.provincia,
      municipio: cliente.domicilio.municipio,
      calle: cliente.domicilio.calle,
      altura: cliente.domicilio.altura,
      pisoDepto: cliente.domicilio.pisoDepto,
      fechaNacimiento: cliente.fechaNacimiento,
      telefonoMovil: cliente.telefono?.movil,
      telefonoFijo: cliente.telefono?.particular,
      nacionalidad: cliente.nacionalidad,
      email: cliente.email,
      estadoCivil: cliente.estadoCivil,
      nupcias: cliente.nupcias,
      nombreConyuge: cliente.datosConyuge?.nombre,
      segundoNombreConyuge: cliente.datosConyuge?.segundoNombre,
      apellidoConyuge: cliente.datosConyuge?.apellido,
      apellidoMaternoConyuge: cliente.datosConyuge?.apellidoMaterno,
      sexo: cliente.sexo,
      cp: cliente.domicilio.cp,
      activo: cliente.activo,
      inmobiliaria: cliente.inmobiliaria,
      razonSocial: cliente.razonSocial,
      createdFromAfip: cliente.createdFromAfip,
      otherAfipInfo: cliente.otherAfipInfo,
      representante: cliente.representante,
    };
    return rta;
  };




  const actualizarStateV2 = (data) => {
    guardarCliente({
      ...cliente,
      ...data
    });
  }

  const findOperacionesByClientId = async () => {
    try {
      const { id } = props.match.params;
      const operacionesDb = await get(`/operaciones/getByCliente/${id}`);
      setOperaciones(operacionesDb);
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  }

  if (!cliente._id) {
    return <Spinner />;
  }

  moment.locale("es-Ar");

  return (
    <div className='card'>
      <div className='card-header'>
        <h3 className='card-title mt-2'>Ver Cliente</h3>
        <button className="btn btn-primary float-right" onClick={props.history.goBack}>Volver</button>
      </div>
      <div className='card-body'>
        <form>
          <FormularioVerCliente
            actualizarState={actualizarState}
            cliente={cliente}
            actualizarStateV2={actualizarStateV2}
            guardarCliente={guardarCliente}
          />

        { nivelRol >= 3 ? 
          <>
            <div className="row">
              <div className="col">
                <h3 className="card-title mb-4">Operaciones por Cliente</h3>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <table className="table table-striped">
                  <thead>
                    <tr className="text-center">
                      <th>Nro Operación</th>
                      <th>Desarrollo</th>
                      <th>Lotes</th>
                      <th>Fecha</th>
                      <th>Estado</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {operaciones?.map(operacion => (
                      <tr key={operacion._id} className="text-center">
                        <td>{operacion.id}</td>
                        <td>{operacion.lote[0].barrio.nombre}</td>
                        <td>{operacion.lote.reduce((acc, lote, index) => acc + lote.numeroLote + (index !== operacion.lote.length - 1 ? ', ' : ''), '')}</td>
                        <td>{moment(operacion.fechaAlta).format('DD/MM/YYYY')}</td>
                        <td>{operacion.estado.estado}</td>
                        <td>
                          <button className="btn btn-primary"
                            onClick={() => props.history.push(`/operaciones/${operacion._id}`)}
                          >Ver</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <h3 className="card-title mb-4">Cesiones por Cliente</h3>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <table className="table table-striped">
                  <thead>
                    <tr className="text-center">
                      <th>Fecha Alta</th>
                      <th>Vendedor</th>
                      <th>Comprador</th>
                      <th>Tipo</th>
                      <th>Estado</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {cesiones.map(cesion => (
                      <tr key={cesion._id} className="text-center">
                        <td>{new Date(cesion.fechaAlta).toLocaleDateString()}</td>
                        <td>{cesion.vendedor?.razonSocial ? cesion.vendedor.razonSocial : `${cesion.vendedor?.nombre?.apellido}, ${cesion.vendedor?.nombre?.nombre}`}</td>
                        <td>{cesion.comprador?.razonSocial ? cesion.comprador.razonSocial : `${cesion.comprador?.nombre?.apellido}, ${cesion.comprador?.nombre?.nombre}`}</td>
                        <td>{cesion.tipo}</td>
                        <td>{cesion.estado}</td>
                        <td>
                          <button className="btn btn-primary"
                            onClick={() => props.history.push(`/listadoCesiones/${cesion._id}`)}
                          >Ver</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
          : null}
        </form>
      </div>
    </div>
  );
};

export default withRouter(VerCliente);
