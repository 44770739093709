import React, {useState, useEffect, useContext} from "react";
import Select from "react-select";
import clienteAxios from "../../../config/axios";
import {CRMContext} from "../../../context/CRMContext";

export const SelectEstadoCivil = ({cliente, actualizarState, disabled = false}) => {
  const [options, setOptions] = useState([]);
  const [auth, guardarAuth] = useContext(CRMContext);
  const [loading, setLoading] = useState(true);
  let token = auth.token || localStorage.getItem("token");

  let defaultValue = {}

  const actualizarEstadoCivil = (e) => {
    const enviar = {
      target: {
        name: "estadoCivil",
        value: e.value,
      },
    };

    actualizarState(enviar);
  };

  const consultaAPI = async () => {
    try {
      const estadoCivil = await clienteAxios.get("/estadoCivil", {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });

      let opt = []

      estadoCivil.data.map((estadoCivil) => {

        let value = estadoCivil;
        let label = estadoCivil.nombre;
        if (estadoCivil.activo)
          opt.push({value, label});

        return opt;
      });
      setOptions(opt);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    consultaAPI();
  }, []);


  if (cliente?.estadoCivil) {
    const estadoCivilCliente = options.find(option => {
      return option.value._id === cliente.estadoCivil._id
    });

    if (estadoCivilCliente) {
      defaultValue = {
        value: estadoCivilCliente.value._id,
        label: estadoCivilCliente.value.nombre,
        detalle: estadoCivilCliente.value.detalle
      };
    }
  }

  if (!cliente || options.length === 0) {
    return (<></>);
  }

  if (loading) {
    return null;
  }

  return (
    <>
      <div className="row">
        <div className="col-md-6 form-group">
          <label>Estado Civil *</label>
          <Select
            options={options}
            name="estadoCivil"
            onChange={actualizarEstadoCivil}
            defaultValue={defaultValue}
            isDisabled={disabled}
          />
        </div>
      </div>
    </>
  );
};
