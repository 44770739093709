import React, { useState, useEffect } from 'react'
import swal from 'sweetalert2';
import DataTable from 'react-data-table-component';
import { Link } from 'react-scroll';
import Spinner from '../../layout/Spinner';
import { get } from '../../../config/apiHelper';
import LotesFiltros from './FilterStockLotes';

const StockBarrios = (props) => {

  const [loading, setLoading] = useState(true);
  const [stock, setStock] = useState({});
  const { id } = props.match.params

  // Configuracion del datatable
  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  const getStock = async () => {
    try {
      const { barrio, lotes, bienes } = await get(`/barrios/stock/${id}`);
      console.log(await get(`/barrios/stock/${id}`))

      const lotesProcesados = lotes.map( dat => {
        let isInversor = dat.isInversor ? "Sí" : "";
        if (dat.isInversor && dat.inversor !== undefined && dat.inversor?.nombreCorto) {
          isInversor = isInversor + ` (${dat.inversor.nombreCorto})`;
        }

        return({
          orientacion: dat.orientacion,
          barrio: dat.barrio?.nombre || '-',
          numeroLote: dat.numeroLote,
          numeroManzana: dat.numeroManzana,
          superficie: dat.superficie,
          tipologia: dat.tipologia?.tipo || '',
          estado: dat.motivoDisponibilidad?.nombre || dat.estado?.estado,
          isInversor,
          proyectoPropietario: dat.proyectoPropietario,
          precio: new Intl.NumberFormat("es-AR", {
            style: "currency",
            currency: "USD",
            currencyDisplay: "symbol",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }).format(dat.precioLista),
          url: `/lotes/editar/${dat._id}`,
          ver: `/lotes/ver/${dat._id}`,
          id: dat._id,
          comentarios: dat.comentarios,
        });
      
      })

      setStock({ barrio, lotes: lotesProcesados, bienes });
    } catch (error) {
      swal("Error", "Failed to fetch stock data", "error");
    } finally {
      setLoading(false);
    }
  }

  const getDetalle = (row) => {
    const { tipo, descripcion } = row;
    if (tipo === 'inmueble') {
      return `${descripcion && descripcion.type ? `Tipo: ${descripcion.type}` : 'Desconocido'}${descripcion && descripcion.direccion ? ` - Dirección: ${descripcion && descripcion.direccion}  ` : ''}`
    } else if (tipo === 'vehiculo') {
      return `Marca: ${descripcion.vehiculo?.brand} - Modelo: ${descripcion.vehiculo?.model} - Version: ${descripcion.vehiculo?.version} - Año: ${descripcion.vehiculo?.year}`
    }
  }

  const getDate = (value) => {
    if (!value) {
      return '';
    }
    return new Intl.DateTimeFormat("es-Ar").format(value);
  }

  const getTipo = (value) => {
    const tipo = {
      inmueble: 'Inmueble',
      vehiculo: 'Vehículo',
    }
    return tipo[value] || 'Desconocido';
  }

  const getEstado = (value) => {
    return value.estado;
  }

  useEffect(() => {
    getStock();
  }, [])

  console.log(stock)
  

  if (loading) {
    return <Spinner />
  }

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Stock de Barrio: {stock?.barrio?.nombre}</h3>
          <button className="btn btn-primary float-right" onClick={props.history.goBack}>Volver</button>
        </div>
        <div className="card-body">


          <div className="row">
            <div className="col-md-12">
              <label>Lotes:</label>
            </div>
            {/* <div className="col-md-12">
              <LotesFiltros />
            </div> */}
            <div className="col-md-12">
              <DataTable
                columns={[
                  {
                    name: "Desarrollo",
                    selector: (row) => row.barrio,
                    sortable: true,
                  },
                  {
                    name: "Lote",
                    selector: (row) => row.numeroLote,
                    sortable: true,
                  },
                  {
                    name: "Manzana",
                    selector: (row) => row.numeroManzana,
                    sortable: true,
                  },
                  {
                    name: "Superficie",
                    selector: (row) => row.superficie,
                    sortable: true,
                  },
                  {
                    name: "Tipología",
                    selector: (row) => row.tipologia,
                    sortable: true,
                  },
                  {
                    name: "Orientación",
                    selector: (row) => row.orientacion,
                    sortable: true,
                  },
                  {
                    name: "Precio",
                    selector: (row) => row.precio,
                    sortable: true,
                  },
                  {
                    name: "Inversor",
                    selector: (row) => row.isInversor,
                    sortable: true,
                  },
                  {
                    name: "Estado",
                    selector: (row) => row.estado,
                    sortable: true,
                    conditionalCellStyles: [
                      {
                        when: row => row.estado?.includes('Disponible'),
                        style: {
                          backgroundColor: '#15c97f',
                          color: 'white',
                        },
                      },
                      {
                        when: row => row.estado?.includes('Bloqueado'),
                        style: {
                          backgroundColor: '#FFA500',
                          color: 'white',
                        },
                      },
                      {
                        when: row => row.estado?.includes("No Disponible - Inversor") || (!row.estado?.includes('Disponible') && !row.estado?.includes('Bloqueado')),
                        style: {
                          backgroundColor: 'gray',
                          color: 'white',
                        },
                      },
                    ],
                  },{
                    name: "Ver",
                    button: true,
                    cell: (row) => (
                      <Link to={row.ver}>
                      <button type="button" className="btn btn-block btn-success">
                        Ver
                      </button>
                    </Link>
                    ),
                  },
                ]}
                data={stock?.lotes}
                pagination
                highlightOnHover
                pointerOnHover
                paginationComponentOptions={paginationComponentOptions}
                fixedHeader
                fixedHeaderScrollHeight="600px"
              />
            </div>
          </div>


          <div className="row">
            <div className="col-md-12">
              <label>Bienes:</label>
            </div>
            <div className="col-md-12">
            <DataTable columns={[
    {
      name: 'Alta',
      selector: 'fechaAlta',
      sortable: true,
      width: '10%',
      format: (row) => getDate(new Date(row.fechaAlta)), // Format the date for display
    },
    {
      name: 'Estado',
      selector: (row) => getEstado(row),
      sortable: true,
      width: '10%'
    },
    {
      name: 'Tipo',
      selector: (row) => getTipo(row.tipo),
      sortable: true,
      width: '10%'
    },
    {
      name: 'Proyeco Propietario',
      selector: (row) => row.proyectoPropietario?.nombre,
      sortable: true,
      width: '10%'
    },
    {
      name: 'Detalle',
      selector: (row) => getDetalle(row),
      sortable: true,
      width: '50%'
    },
    {
      name: 'Ver',
      button: true,
      cell: (row) => (
        <Link to={`/bienes/${row._id}`}>
          <button type="button" className="btn btn-block btn-success">
            Ver
          </button>
        </Link>
      ),
      width: '10%'
    },
  ]} data={stock.bienes} pagination defaultSortField="fechaAlta" defaultSortAsc={false} />
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default StockBarrios