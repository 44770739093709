import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import NumberFormat from "react-number-format";

import Spinner from "../layout/Spinner";
import moment from "moment";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { get } from "../../config/apiHelper";

const VerLote = ({ history, ...props }) => {
  const [lote, setLote] = useState({ loading: true });
  const [operacionesRelacionadas, setOperacionesRelacionadas] = useState([]);
  const [cesiones, setCesiones] = useState([]);
  const nivelRol = localStorage.getItem('rolId');

  const { id } = props.match.params;

  const getCesiones = async () => {
    try {
      const response = await get(`/api/notarial/cesiones/lote/${id}`);
      console.log("Cesiones", response);
      setCesiones(response);
    } catch (error) {
      console.log(error);
    }
  }


  // Obtener los datos del barrio a editar
  const consultarAPILote = async () => {
    try {
      const loteDB = await get(`/lote/${id}`);

      setLote({
        loading: false,
        ...loteDB,
      });
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        history.push("/login");
      }
      console.log(error);
    }
  };


  const getLoteOperaciones = async () => {
    try {
      const response = await get(`operaciones/lote/${id}`);

      setOperacionesRelacionadas(response);
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        history.push("/login");
      }
      console.log(error);
    }
  }



  useEffect(() => {
    getCesiones();
    getLoteOperaciones();
    consultarAPILote();
  }, []);


  const spliceComentario = (comentario) => {
    const lineas = comentario.split('\n');
    return lineas;
  }



  const cesionesColumns = [
    {
      name: "Fecha de alta de Cesión",
      selector: (row) => new Date(row.fechaAlta).toLocaleDateString(),
      sortable: true,
    },
    {
      name: "Comprador",
      selector: (row) => row.comprador?.razonSocial ? row.comprador.razonSocial : `${row.comprador?.nombre?.apellido}, ${row.comprador?.nombre?.nombre}`,
      sortable: true,
    },
    {
      name: "Vendedor",
      selector: (row) => row.vendedor?.razonSocial ? row.vendedor.razonSocial : `${row.vendedor?.nombre?.apellido}, ${row.vendedor?.nombre?.nombre}`,
      sortable: true,
    },
    {
      name: "Tipo",
      selector: (row) => row.tipo,
      sortable: true,
    },
    {
      name: "Estado",
      selector: (row) => row.estado,
      sortable: true,
    },
    {
      name: "Ver",
      button: true,
      cell: (row) => (
        <Link to={`/listadoCesiones/${row._id}`}>
          <button type="button" className="btn btn-block btn-success">
            Ver
          </button>
        </Link>
      ),
    }
  ];

  const columns = [
    {
      name: "Fecha Alta",
      selector: (row) => moment(row.fechaAlta)
        .format("DD-MM-YYYY - HH:mm"),
      sortable: true,
    },
    {
      name: "Tipo",
      selector: (row) => row.tipo ?? '',
      sortable: true,
    },
    {
      name: "Cliente",
      selector: (row) => row ? (row.cliente.razonSocial ? row.cliente.razonSocial : `${row.cliente?.nombre?.apellido ?? ''}, ${row.cliente?.nombre?.nombre ?? ''}`) : '',
      sortable: true,
    },
    {
      name: "Estado",
      selector: (row) => row.estado?.estado,
      sortable: true,
    },
    {
      name: "Ver",
      button: true,
      cell: (row) => (
        <Link to={`/operaciones/${row._id}`}>
          <button type="button" className="btn btn-block btn-success">
            Ver
          </button>
        </Link>
      ),
    },
  ];

  const data = operacionesRelacionadas;


  if (lote.loading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title mt-2">Ver Lote</h3>
          <button className="btn btn-primary float-right ml-3 mr-3" onClick={history.goBack}>Volver</button>
          {nivelRol >= 3 ? 
            <button className="btn btn-primary float-right ml-3 mr-3" disabled={nivelRol < 3} onClick={() => history.push(`/lotes/editar/${lote?._id}`)}>Editar</button>
          : null}
        </div>

        <div className="card-body">
          <form>
            <div className="row">
              <div className="col-md-6 form-group">
                <label>Desarrollo</label>
                <input
                  type="text"
                  className="form-control"
                  value={lote.barrio.nombre}
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 form-group">
                <label>Estado</label>
                <input
                  type="text"
                  className="form-control"
                  value={lote.estado?.estado}
                  readOnly={true}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-2 form-group">
                <label>Numero de Lote</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Numero de Lote"
                  name="numeroLote"
                  value={lote.numeroLote}
                  disabled={true}
                />
              </div>
              <div className="col-md-2 form-group">
                <label>Número de Manzana</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Numero de manzana"
                  name="numeroManzana"
                  value={lote.numeroManzana}
                  disabled={true}
                />
              </div>
              <div className="col-md-2 form-group">
                <label>Superficie</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Superficie"
                  name="superficie"
                  value={lote.superficie}
                  disabled={true}
                />
              </div>
              <div className="col-md-3 form-group">
                <label>Precio de Lista</label>
                <NumberFormat
                  className="form-control"
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"$"}
                  value={lote.precioLista}
                  disabled={true}
                />
              </div>
              <div className="col-md-3 form-group">
                <label>Tipología</label>
                {lote.estado?.id !== 0 &&
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Tipología"
                    name="tipologia"
                    value={lote.tipologia.tipo}
                    disabled={true}
                  />
                }
                {lote.estado?.id === 0 &&
                <input
                  type="text"
                  className="form-control"
                  placeholder="Tipología"
                  name="tipologia"
                  value={lote.tipologia.tipo}
                  disabled={true}
                />
                }
              </div>
              <div className="col-md-3 form-group">
                <label>Motivo Disponibilidad</label>
                <input
                  type="text"
                  className="form-control"
                  value={lote.motivoDisponibilidad?.nombre}
                  disabled={true}
                />
              </div>
              <div className="col-md-3 form-group">
                <label>Orientación</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Orientación"
                  name="orientacion"
                  value={lote.orientacion || ''}
                  disabled={true}
                />
              </div>
              <div className="col-md-3 form-group">
                <label>Adjudicado</label>
                <input
                  type="text"
                  className="form-control"
                  name="adjudicado"
                  value={lote.adjudicado || ''}
                  disabled={true}
                />
              </div>
              <div className="col-md-3 form-group">
                <label>Es Lote Inversor?</label>
                <input
                  type="checkbox"
                  className="form-control"
                  name="activo"
                  checked={lote?.isInversor}
                  disabled
                />
              </div>
              <div className="col-md-3 form-group">
                <label>Proyecto Propietario</label>
                <input
                  type="text"
                  className="form-control"
                  value={lote?.proyectoPropietario?.nombre}
                  disabled
                />
              </div>
            </div>

            { nivelRol >= 3 ?
              <div className="row mb-4">
                <div className="col">
                  <label>Inversionista:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={lote.inversor?.nombreCorto}
                    disabled={true}
                  />
                </div>
              </div>
            : null }

            { nivelRol >= 3 ? 
            <>
              <div className="row mt-4">
                <div className="col-md-12">
                  <div className="card-header">
                    <h3 className="card-title">Comentarios:</h3>
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  {/* The time line */}
                  <div className="timeline">
                    {lote.comentarios?.length > 0 ? lote.comentarios.map((comentario, index) => {
                      return (
                        <div key={index}>
                          <i className="fas fa-envelope bg-blue" />
                          <div className="timeline-item">
                            <span className="time">
                              <i className="fas fa-clock" />{" "}
                              {moment(comentario.fecha)
                                //   .utcOffset(180)
                                .format("DD-MM-YYYY - HH:mm")}
                            </span>
                            <h3 className="timeline-header">
                              {`Usuario: ${comentario.usuario?.apellido || '< Sin Apellido >'}, ${comentario.usuario?.nombre || '< Sin Nombre >'}`} - {comentario.usuario?.inmobiliaria?.nombre || '< Sin Nombre Inmobiliaria>'}
                            </h3>
                            {spliceComentario(comentario.comentario).map((linea) => {
                              return <div className="timeline-body">{linea}</div>
                            })}
                          </div>
                        </div>
                      );
                    }) : ''}
                  </div>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-md-12">
                  <div className="card-header">
                    <h3 className="card-title">Operaciones Relacionadas:</h3>
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <DataTable
                    data={data}
                    columns={columns}
                  />
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-md-12">
                  <div className="card-header">
                    <h3 className="card-title">Cesiones Relacionadas:</h3>
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <DataTable
                    data={cesiones}
                    columns={cesionesColumns}
                  />
                </div>
              </div>
            </>
            : null}


          </form>
        </div>
      </div>
    </>
  );
};

export default withRouter(VerLote);
