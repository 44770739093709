import React, { useRef, useEffect, useState } from 'react'

const Email = (props) => {
  const { client, emailSetter } = props;
  const { email } = client;
  const inputRef = useRef(null);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (email) {
      inputRef.current.value = email;
      setDisabled(true);
    }
  }, []);

  return (
    <div className='mb-4'>
      <div className="row">
        <label>Email:</label>
      </div>
      <div className="row">
        <div className="col-md-6">
          <label>Email</label>
          <input className="form-control" onChange={(e) => emailSetter(e.target.value)} type="email" ref={inputRef} disabled={disabled}/>
        </div>
      </div>
    </div>
  )
}

export default Email