import React from 'react'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';

const DetalleDuenoOperacion = (props) => {
  const { operacion } = props;

  if(!operacion) return null;

  return (
    <>
      <div className="card-header row">
        <div className="col-md-12">
          <h3 className="card-title">
            <strong>Cliente y Lotes:</strong>
          </h3>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col">
            <label>Cliente:</label>
              <p className='ml-3'>{operacion.cliente?.razonSocial ? operacion.cliente?.razonSocial : operacion.cliente?.nombre?.apellido + ' ' + operacion.cliente?.nombre?.nombre} <button className='btn btn-primary ml-3 mb-1' onClick={() => props.history.push(`/clientes/ver/${operacion.cliente._id}`)}>Ver</button></p>
          </div>
          <div className="col">
            <label>Lotes:</label>
            {operacion.lote.map(lote => {
              return (
                <>
                <p className='ml-3'>{lote.barrio.nombre} - {lote.numeroLote}  <button className='btn btn-primary ml-3 mb-1' onClick={() => props.history.push(`/lotes/ver/${lote._id}`)}>Ver</button></p>
                </>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}

export default withRouter(DetalleDuenoOperacion)