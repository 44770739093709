import React, {useContext} from "react";

// Routing
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import {CRMContext, CRMProvider} from "./context/CRMContext";

// Layout
import "./App.css";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Dashboard from "./components/dashboard/Dashboard";

import Menu from "./components/menu/Menu";

// Barrios
import Barrios from "./components/barrios/Barrios";
import NuevoBarrio from "./components/barrios/NuevoBarrio";
import MostrarBarrio from "./components/barrios/MostrarBarrio";
import EditarBarrio from "./components/barrios/EditarBarrio";

// Lotes
import Lotes from "./components/lotes/Lotes";
import AgregarLotes from "./components/lotes/AgregarLotes";
import EditarLote from "./components/lotes/EditarLote";
import EditarLotesMasivo from "./components/lotes/EditarLotesMasivo";
import ExportarLotes from "./components/lotes/ExportarLotes";

// Clientes
import NuevoCliente from "./components/clientes/NuevoCliente";
import ListadoClientes from "./components/clientes/ListadoClientes";
import EditarCliente from "./components/clientes/EditarCliente";

// Operaciones
import ListadoOperaciones from "./components/operaciones/ListadoOperaciones";
import Operacion from "./components/operaciones/Operacion";
import NuevaOperacion from "./components/operaciones/nuevaOperacion/components/NuevaOperacion";
import Login from "./components/login/login";


// Readquisicion de lotes
import ReadquisicionLotes from "./components/readquisicion/ReadquisicionLotes";
import ListadoReadquisicionLotes from "./components/readquisicion/ListadoReadquisicionLotes";
import EditarReadquisicionLotes from "./components/readquisicion/EditarReadquisicionLotes";

// Confirmar Operaciones
import ListadoConfirmarOperaciones from "./components/operaciones/ListadoConfirmarOperaciones";

// Inmobiliarias
import ListadoInmobiliarias from "./components/inmobiliarias/ListadoInmobiliarias";
import NuevaInmobiliaria from "./components/inmobiliarias/NuevaInmobiliaria";
import EditarInmobiliaria from "./components/inmobiliarias/EditarInmobiliaria";

// Usuarios
import ListadoUsuarios from "./components/usuarios/ListadoUsuarios";
import NuevoUsuario from "./components/usuarios/NuevoUsuario";
import EditarUsuario from "./components/usuarios/EditarUsuario";


// Escribanias
import ListadoEscribanias from "./components/escribanias/ListadoEscribanias";
import NuevaEscribania from "./components/escribanias/NuevaEscribania";
import EditarEscribania from "./components/escribanias/EditarEscribania";

//Nacionalidades
import ListadoNacionalidades from "./components/nacionalidades/listadoNacionalidades";
import NuevaNacionalidad from "./components/nacionalidades/nuevaNacionalidad";
import EditarNacionalidad from "./components/nacionalidades/editarNacionalidad";

//Estado Civil
import ListadoEstadoCivil from "./components/estadoCivil/listadoEstadoCivil"
import EditarEstadoCivil from "./components/estadoCivil/editarEstadoCivil"
import NuevoEstadoCivil from "./components/estadoCivil/nuevoEstadoCivil"

// Bienes
import Bienes from "./components/bienes/Bienes";
import Bien from "./components/bienes/Bien";
import AgregarBien from './components/bienes/AgregarBien';

// Parametros
import ParametrosListado from "./components/parametros/parametrosListado";
import Parametro from "./components/parametros/parametro";
import ListadoFirmantesLife from "./components/firmantesLife/ListadoFirmantesLife";
import FormularioFirmantesLife from "./components/firmantesLife/FormularioFirmantesLife";
import ListaDePrecio from "./components/listasDePrecios/ListaDePrecio";
import NotFound from "./components/generics/NotFound";
import ListadoBloqueos from "./components/bloqueos/ListadoBloqueos";
import NuevoBloqueo from "./components/bloqueos/NuevoBloqueo";
import { EditarFirmantes } from "./components/operaciones/components/EditarFirmantes";
import ExcelUploader from "./components/lotes/EditarMasivos";
import AltaMasivaLotes from "./components/lotes/AltaMasiva";
import { ListadoEstadosOperaciones } from "./components/estadosOperaciones/listado";
import { FormularioEditarEstado } from "./components/estadosOperaciones/formulario";
import { ListadoLotesFormaPago } from "./components/lotes/ListadoLotesFormaPago";
import { Logs } from "./components/logs/Logs";
import { ListadoMotivosDisponibilidad } from "./components/motivosDisponibilidad/listado";
import { FormularioEditarMotivo } from "./components/motivosDisponibilidad/formulario";
import { FormularioAltaMotivo } from "./components/motivosDisponibilidad/formularioAlta";
import { FormularioCrearLote } from "./components/lotes/CrearLote/CrearLote";
import { FormularioDocumentacion } from "./components/documentacion/FormularioDocumentacion";
import { ListadoDocumentacion } from "./components/documentacion/ListadoDocumentacion";
import { ListadoCategorias } from "./components/documentacion/ListadoCategorias";
import { FormularioCategoria } from "./components/documentacion/FormularioCategoria";
import { FormularioAltaInversionista } from "./components/inversionistas/formularioAlta";
import { ListadoInversionistas } from "./components/inversionistas/listado";
import { FormularioEditarInversionista } from "./components/inversionistas/formularioEditar";
import CalendarioFirmas from "./components/operaciones/CalendarioFirmas/CalendarioFirmas";
import FormularioAlta from "./components/notarial/AltaCesiones/FormularioAlta";
import ListadoDeCesiones from "./components/notarial/AltaCesiones/ListadoCesiones";
import ListadoPrecios from "./components/listaDePrecios/ListaDePrecios";
import ListadoListaDePrecios from "./components/listaDePrecios/ListadoListaDePrecios";
import VerListaDePrecios from "./components/listaDePrecios/VerListaDePrecios";
import ShowExistentClient from "./components/clientes/new/ShowExistentClient";
import EditarListaDePrecios from "./components/listaDePrecios/EditarListaDePrecios";
import MostrarCesion from "./components/notarial/AltaCesiones/MostrarCesion";
import VerLote from "./components/lotes/VerLote";
import VerCliente from "./components/clientes/VerCliente";
import StockBarrios from "./components/barrios/components/StockBarrios";

function App() {
  const [auth, guardarAuth] = useContext(CRMContext);
  console.log(process.env.REACT_APP_BACKEND_URL);

  return (
    <Router>
      <CRMProvider value={[auth, guardarAuth]}>
        <div className='wrapper'>
          <Header/>
          <Menu/>

          <div className='content-wrapper'>
            <div className='content-header'>
              <div className='container-fluid'>
                <div className='row mb-2'></div>
              </div>
            </div>
            <section className='content'>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-md-12'>
                    <Switch>
                      <Route exact path='/login' component={Login}/>

                      <Route exact path='/' component={Dashboard}/>

                      <Route exact path='/barrios' component={Barrios}/>
                      <Route exact path='/barrios/stock/:id' component={StockBarrios}/>
                      <Route
                        exact
                        path='/barrios/nuevo'
                        component={NuevoBarrio}
                      />
                      <Route
                        exact
                        path='/barrios/editar/:id'
                        component={EditarBarrio}
                      />
                      <Route
                        exact
                        path='/barrios/ver/:id'
                        component={MostrarBarrio}
                      />
                      {/* <Route
                        exact
                        path='/barrios/configurarTasas'
                        component={TasasBarrios}
                      /> */}
                      <Route
                        exact
                        path="/lotes/nuevo"
                        component={FormularioCrearLote}
                      />
                      <Route
                        exact
                        path="/lotes/entregados"
                        component={ListadoLotesFormaPago}
                      />
                      <Route
                        exact
                        path='/lotes/altaMasiva/:id'
                        component={AltaMasivaLotes}
                      />
                      <Route
                        exact
                        path='/lotes/masivo/:id'
                        component={ExcelUploader}
                      />
                      <Route
                        exact
                        path='/lotes/agregar'
                        component={AgregarLotes}
                      />
                      <Route
                        exact
                        path='/lotes/editar'
                        component={EditarLotesMasivo}
                      />
                      <Route
                        exact
                        path='/lotes/exportar'
                        component={ExportarLotes}
                      />
                      <Route
                        exact
                        path='/lotes/editar/:id'
                        component={EditarLote}
                      />
                      <Route
                        exact
                        path='/lotes/ver/:id'
                        component={VerLote}
                      />
                      <Route exact path='/lotes/:id' component={Lotes}/>
                      <Route exact path='/lotes' component={Lotes}/>

                      <Route
                        exact
                        path='/clientes/nuevo'
                        component={NuevoCliente}
                      />
                      <Route
                        exact
                        path='/clientes/editar/:id'
                        component={EditarCliente}
                      />
                      <Route
                        exact
                        path='/clientes/ver/:id'
                        component={VerCliente}
                      />
                      <Route
                        exact
                        path='/clientes'
                        component={ListadoClientes}
                      />

                      <Route
                        exact
                        path='/operaciones/calendarioFirmas'
                        component={CalendarioFirmas}
                      />
                      <Route
                        exact
                        path='/operaciones/editarFirmantes/:id'
                        component={EditarFirmantes}
                      />
                      <Route
                        exact
                        path='/operaciones'
                        component={ListadoOperaciones}
                      />
                      <Route
                        exact
                        path='/operaciones/nueva'
                        component={NuevaOperacion}
                      />

                      <Route
                        exact
                        path='/operaciones/readquisicion'
                        component={ListadoReadquisicionLotes}
                      />

                      <Route
                        exact
                        path='/operaciones/readquisicion/nueva'
                        component={ReadquisicionLotes}
                      />

                      <Route
                        exact
                        path='/operaciones/readquisicion/:id'
                        component={EditarReadquisicionLotes}
                      />

                      <Route
                        exact
                        path='/operaciones/confirmar'
                        component={ListadoConfirmarOperaciones}
                      />

                      <Route
                        exact
                        path='/operaciones/:id'
                        component={Operacion}
                      />

                      <Route
                        exact
                        path='/configuracion/inmobiliarias'
                        component={ListadoInmobiliarias}
                      />
                      <Route
                        exact
                        path='/configuracion/inmobiliarias/nueva'
                        component={NuevaInmobiliaria}
                      />
                      <Route
                        exact
                        path='/configuracion/inmobiliarias/editar/:id'
                        component={EditarInmobiliaria}
                      />
                      <Route
                        exact
                        path='/configuracion/usuarios'
                        component={ListadoUsuarios}
                      />
                      <Route
                        exact
                        path='/configuracion/usuarios/nuevo'
                        component={NuevoUsuario}
                      />
                      <Route
                        exact
                        path='/configuracion/usuarios/editar/:id'
                        component={EditarUsuario}
                      />

                      <Route
                        exact
                        path='/configuracion/escribanias'
                        component={ListadoEscribanias}
                      />

                      <Route
                        exact
                        path='/configuracion/escribanias/nueva'
                        component={NuevaEscribania}
                      />

                      <Route
                        exact
                        path='/configuracion/escribanias/editar/:id'
                        component={EditarEscribania}
                      />

                      <Route
                        exact
                        path='/configuracion/nacionalidades'
                        component={ListadoNacionalidades}
                      />
                      <Route
                        exact
                        path='/configuracion/nacionalidades/nueva'
                        component={NuevaNacionalidad}
                      />
                      <Route
                        exact
                        path='/configuracion/nacionalidades/editar/:id'
                        component={EditarNacionalidad}
                      />
                      <Route
                        exact
                        path='/configuracion/estadoCivil'
                        component={ListadoEstadoCivil}
                      />
                      <Route
                        exact
                        path='/configuracion/estadoCivil/nuevo'
                        component={NuevoEstadoCivil}
                      />

                      <Route
                        exact
                        path='/configuracion/estadoCivil/editar/:id'
                        component={EditarEstadoCivil}
                      />
                      <Route
                        exact
                        path={'/bienes'}
                        component={Bienes}
                      />
                      <Route
                        exact
                        path={'/bienes/nuevo'}
                        component={AgregarBien}
                      />
                      <Route
                        exact
                        path={'/bienes/:id'}
                        component={Bien}
                      />
                      <Route
                        exact
                        path={'/configuracion/parametros'}
                        component={ParametrosListado}
                      />
                      <Route
                        exact
                        path={'/configuracion/parametros/:id'}
                        component={Parametro}
                      />
                      <Route
                        exact
                        path={'/configuracion/firmantesLife'}
                        component={ListadoFirmantesLife}
                      />
                      <Route
                        exact
                        path={'/configuracion/firmantesLife/nuevo'}
                        component={FormularioFirmantesLife}
                      />
                      <Route
                        exact
                        path={`/configuracion/firmantesLife/editar/:id`}
                        component={FormularioFirmantesLife}
                      />
                      <Route
                        exact
                        path={'/configuracion/listaPrecios/:id'}
                        component={ListaDePrecio}
                      />
                      <Route
                        exact
                        path={'/bloqueos/nuevo'}
                        component={NuevoBloqueo}
                      />
                      <Route
                        exact
                        path={'/bloqueos'}
                        component={ListadoBloqueos}
                      />
                      <Route
                        exact
                        path="/estadosOperaciones/listado"
                        component={ListadoEstadosOperaciones}
                      />
                      <Route
                        exact
                        path="/estadosOperaciones/editar/:id"
                        component={FormularioEditarEstado}
                      />
                      <Route
                        exact
                        path="/motivoDisponibilidad/listado"
                        component={ListadoMotivosDisponibilidad}
                      />
                      <Route
                        exact
                        path="/motivoDisponibilidad/editar/:id"
                        component={FormularioEditarMotivo}
                      />
                      <Route
                        exact
                        path="/motivoDisponibilidad/nuevo"
                        component={FormularioAltaMotivo}
                      />
                      <Route
                        exact
                        path="/configuracion/inversionistas/listado"
                        component={ListadoInversionistas}
                      />
                      <Route
                        exact
                        path="/configuracion/inversionistas/editar/:id"
                        component={FormularioEditarInversionista}
                      />
                      <Route
                        exact
                        path="/configuracion/inversionistas/nuevo"
                        component={FormularioAltaInversionista}
                      />
                      <Route
                        exact
                        path="/documentacion/categorias/listado"
                        component={ListadoCategorias}
                      />
                      <Route
                        exact
                        path="/documentacion/categorias/nuevo"
                        component={FormularioCategoria}
                      />
                      <Route
                        exact
                        path="/documentacion/nuevo"
                        component={FormularioDocumentacion}
                      />
                      <Route
                        exact
                        path="/documentacion/editar/:id"
                        component={FormularioDocumentacion}
                      />
                      <Route
                        exact
                        path='/documentacion/archivosAdjuntos'
                        component={ListadoDocumentacion}
                      />
                      <Route
                        exact
                        path='/logs'
                        component={Logs}
                      />
                      <Route
                        exact
                        path='/altaCesion'
                        component={FormularioAlta}
                      />
                      <Route
                        exact
                        path='/listadoCesiones'
                        component={ListadoDeCesiones}
                      />
                      <Route
                        exact
                        path='/listadoCesiones/:id'
                        component={MostrarCesion}
                      />
                      <Route
                        exact
                        path='/listaDePrecios/nuevo'
                        component={ListadoPrecios}
                      />
                      <Route
                        exact
                        path='/listaDePrecios/editar/:id'
                        component={EditarListaDePrecios}
                      />
                      <Route
                        exact
                        path='/listaDePrecios/:id'
                        component={VerListaDePrecios}
                      />
                      <Route
                        exact
                        path='/listaDePrecios'
                        component={ListadoListaDePrecios}
                      />
                      <Route
                        exact
                        path='/concepto/cliente/'
                        component={ShowExistentClient}
                      />
                      <Route path="*" component={NotFound} />
                    </Switch>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <Footer/>
        </div>
      </CRMProvider>
    </Router>
  );
}

export default App;
