import React, { useEffect, useState } from 'react';
import clienteAxios from "../../../config/axios";
import Select from "react-select";
import Swal from "sweetalert2";
import Spinner from "../../layout/Spinner";

function FiltroListaPrecios(props) {

  const { token, idBarrio, nombreBarrio } = props;

  const [tipologias, setTipologias] = useState([]);
  const [estadosLotes, setEstadosLotes] = useState([]);
  const [motivosDisponibilidad, setMotivosDisponibilidad] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [tipoFilter, setTipoFilter] = useState('');
  const [estadoFilter, setEstadoFilter] = useState('');
  const [lotesFilter, setLotesFilter] = useState('');
  const [lotesManuales, setLotesManuales] = useState('');
  const [manzanasFilter, setManzanasFilter] = useState('');
  const [motivoDisponibilidadFilter, setMotivoDisponibilidadFilter] = useState('');


  const handleTipoFilter = (e) => {
    const tipos = e.map(element => (element.value)).join(',');
    setTipoFilter(tipos);
  }

  const handleEstadoFilter = (e) => {
    const estados = e.map(element => (element.value)).join(',');
    setEstadoFilter(estados);
  }

  const handleMotivoFilter = (e) => {
    const motivos = e.map(element => (element.value)).join(',');
    setMotivoDisponibilidadFilter(motivos);
  }

  const handleManzanaFilter = (e) => {
    setManzanasFilter(e.target.value.trim());
  }

  const handleLotesFilter = (e) => {
    setLotesFilter(e.target.value.trim());
  }

  const handleLotesManuales = (e) => {
    setLotesManuales(e.target.value.trim());
  }

  const consultarAPITipologias = async () => {
    try {
      const tipologiasDB = await clienteAxios.get("/tipologias", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      let tipologiasSelect = tipologiasDB.data.map((tipologia) => {
        return {
          value: tipologia._id,
          label: tipologia.tipo,
        };
      });
      setTipologias(tipologiasSelect);
    } catch (error) {
      console.log(error);
    }
  };

  const consultarAPIEstadosLotes = async () => {
    try {
      const estadosDB = await clienteAxios.get("/estadosLotes", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      let estadosLotes = estadosDB.data.map((estado) => {
        return {
          value: estado._id,
          label: estado.estado,
        };
      });
      setEstadosLotes(estadosLotes);
    } catch (error) {
      console.log(error);
    }
  };

  const consultarAPIMotivoDisponibilidad = async () => {
    try {
      const motivosDB = await clienteAxios.get("/motivoDisponibilidad", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      let motivoDisponibilidad = motivosDB.data.data.map((motivo) => {
        return {
          value: motivo._id,
          label: motivo.nombre,
        };
      });

      setMotivosDisponibilidad(motivoDisponibilidad);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    consultarAPITipologias();
    consultarAPIEstadosLotes();
    consultarAPIMotivoDisponibilidad();
  }, []);

  const getDate = () => {
    let date = new Date()

    let month = date.getMonth() + 1
    let year = date.getFullYear()

    return `${month}-${year}`;
  }


  const descargarListaPrecios = async () => {
    if (!idBarrio) {
      return;
    }
    try {
      setShowSpinner(true);
      let urlQuery = `/listaPrecios/generarListaPrecio/${idBarrio}`;
      if (tipoFilter || estadoFilter || lotesFilter || manzanasFilter || motivoDisponibilidadFilter) {
        urlQuery += '?';
      }
      if (tipoFilter) {
        urlQuery += `&tipologia=${tipoFilter}`;
      }
      if (estadoFilter) {
        urlQuery += `&estado=${estadoFilter}`;
      }
      if (lotesFilter) {
        urlQuery += `&numeroLote=${lotesFilter}`;
      }
      if (manzanasFilter) {
        urlQuery += `&numeroManzana=${manzanasFilter}`;
      }
      if (motivoDisponibilidadFilter) {
        urlQuery += `&motivoDisponibilidad=${motivoDisponibilidadFilter}`;
      }
      if (lotesManuales) {
        urlQuery += `&lotesManuales=${lotesManuales}`;
      }



      const rta = await clienteAxios.get(urlQuery, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([rta.data]));
      const link = document.createElement("a");
      link.href = url;


      link.setAttribute("download", `${nombreBarrio}-lista-precios-${getDate()}.pdf`);
      document.body.appendChild(link);
      link.click();

    } catch (error) {

      Swal.fire({
        type: "error",
        title: "Hubo un error",
        text: "Hubo un error",
      });
      console.log(error);
    } finally {
      setTipoFilter('');
      setEstadoFilter('');
      setLotesFilter('');
      setManzanasFilter('');
      setShowSpinner(false);
    }
  };

  if (showSpinner) {
    return (
      <div style={{ 'height': 30 + 'vh' }}>
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <div className="form-group m-3">
        <label>Filtros:</label>
        <div className="row mb-3">
          <div className="col-md-3">
            <label>Número de Lote:</label>
            <input
              type="text"
              onChange={handleLotesFilter}
              placeholder="Número de Lote"
              className="form-control"
            />
          </div>
          <div className="col-md-3">
            <label>Número de Manzana:</label>
            <input
              type="text"
              onChange={handleManzanaFilter}
              placeholder="Número de Manzana"
              className="form-control"
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-3">
            <label>Tipología:</label>
            <Select
              options={tipologias}
              isMulti={true}
              onChange={handleTipoFilter}
            />
          </div>
          <div className="col-md-3">
            <label>Estado del Lote:</label>
            <Select
              options={estadosLotes}
              isMulti={true}
              onChange={handleEstadoFilter}
            />
          </div>
          <div className="col-md-3">
            <label>Motivo de Disponibilidad:</label>
            <Select
              options={motivosDisponibilidad}
              isMulti={true}
              onChange={handleMotivoFilter}
            />
          </div>
          <div className="col-md-3">
            <label>Agregar lotes:</label>
            <input
              type="text"
              onChange={handleLotesManuales}
              placeholder="Número de Lote"
              className="form-control"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-9"></div>
          <div className="col-md-3">
            <button className="btn btn-block btn-success float-right" onClick={descargarListaPrecios}>Consultar</button>
          </div>
        </div>


      </div>
    </>
  );
}

export default FiltroListaPrecios;