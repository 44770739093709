import { create } from 'zustand';

const listadoLotesStockStore = create((set) => ({
    filter: {},
    setFilter: (filter) => set({ filter }),
    paginator: {
        page: 1,
        limit: 10
    },
    setPaginator: (paginator) => set({ paginator }),
}));

export default listadoLotesStockStore;