import React, { useEffect, useState, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import clienteAxios from "../../config/axios";
import { CRMContext } from "../../context/CRMContext";
import Spinner from "../layout/Spinner";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import FiltroListaPrecios from "./components/FiltroListaPrecios";
import FormularioAdjuntarArchivoBienes from "../bienes/components/FormularioAdjuntarArchivoBienes";

function Barrios(props) {
  const [barrios, guardarBarrios] = useState([]);
  const [barriosOriginal, setBarriosOriginal] = useState([]);
  const nivelRol = localStorage.getItem("rolId");
  const [loadingInfo, setLoadingInfo] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);
  const [idBarrio, setIdBarrio] = useState(null);
  const [nombreBarrio, setNombreBarrio] = useState(null);
  const [auth, guardarAuth] = useContext(CRMContext);
  const idUsuario = localStorage.getItem("idUsuario");
  let token = auth.token || localStorage.getItem("token");


  useEffect(() => {
    // Obtener query params
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const tipo = params.get('tipo');


    const consultarAPI = async () => {
      setLoadingInfo(true);
      try {
        let url = "/barrios?campos=nombre,ciudad,direccion,tipo,masterplan";
        if (tipo && ['Urbano', 'Industrial'].includes(tipo)) {
          url += `&tipo=${tipo}`
        }

        const barriosConsulta = await clienteAxios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            Id: idUsuario,
          },
        });

        const arrayOriginal = barriosConsulta.data.map((barrio) => ({
          id: barrio._id,
          nombre: barrio.nombre,
          ciudad: barrio.ciudad,
          direccion: barrio.direccion,
          masterplan: barrio.masterplan,
          url: `/lotes/${barrio._id}`,
          verInfo: `/barrios/ver/${barrio._id}`,
          editar: `/barrios/editar/${barrio._id}`,
          editarMasivo: `/lotes/masivo/${barrio._id}`,
          listaPrecios: `/configuracion/listaPrecios/${barrio._id}`,
          stock: `/barrios/stock/${barrio._id}`,
        }));

        guardarBarrios(arrayOriginal);
        setBarriosOriginal(arrayOriginal);
      } catch (error) {
        if (error.response?.status === 500) {
          localStorage.clear();
          props.history.push("/login");
        }
        console.log(error);
      } finally {
        setLoadingInfo(false);
      }
    };
    consultarAPI();
  }, [idUsuario, props, token]);

  const obtenerArchivo = async (nombreArchivo) => {
    try {
      const response = await clienteAxios.get(`/archivosAdjuntos/${nombreArchivo}`, {
        headers: { Authorization: `Bearer ${token}` },
        responseType: "blob",
      });

      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${nombreArchivo}`);
        document.body.appendChild(link);
        link.click();
      }
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };

  const descargarListaPrecios = (id) => {
    setIdBarrio(id);
    const barrio = barriosOriginal.find((barrio) => barrio.id === id);
    setNombreBarrio(barrio?.nombre ?? "sin nombre");
  };

  // Datatable columns with dropdown menu
  const columns = [
    {
      name: "Nombre",
      selector: (row) => row.nombre,
      sortable: true,
    },
    {
      name: "Ciudad",
      selector: (row) => row.ciudad,
      sortable: true,
    },
    {
      name: "Dirección",
      selector: (row) => row.direccion,
      sortable: true,
    },
    {
      name: "MP",
      button: true,
      cell: (row) => (
        <>
          <button type="button" className="btn btn-block btn-success mr-3 ml-3"
            disabled={!row.masterplan?.nombreArchivo}
                  onClick={() => obtenerArchivo(row.masterplan.nombreArchivo)}>
            MP





          </button>
        </>
      ),
    },
    {
      name: "Lista de Precios",
      button: true,
      cell: (row) => (
        <>
          <button type="button" className="btn btn-block btn-success mr-3 ml-3"
                  onClick={() => descargarListaPrecios(row.id)} data-toggle="modal"
                  data-target="#modal-archivos">
            <i className="fas fa-dollar-sign"></i>
          </button>
        </>
      ),
    },
    {
      name: "Ver Lotes",
      button: true,
      cell: (row) => (
        <Link to={row.url}>
          <button type="button" className="btn btn-block btn-success">
            Ver Lotes
          </button>
        </Link>
      ),
    },
    {
      name: "Ver Info",
      button: true,
      cell: (row) => (
        <Link to={row.verInfo}>
          <button type="button" className="btn btn-block btn-success">
            Ver Info
          </button>
        </Link>
      ),
    },
  ];

  if (nivelRol >= 3) {
    columns.push({
      name: "Editar",
      button: true,
      cell: (row) => (
        <Link to={row.editar}>
          <button type="button" className="btn btn-block btn-success">
            Editar
          </button>
        </Link>
      ),
    })
    columns.push({
      name: "Editar Masivo",
      button: true,
      cell: (row) => (
        <Link to={row.editarMasivo}>
          <button type="button" className="btn btn-block btn-success">
            Editar
          </button>
        </Link>
      ),
    })
    columns.push({
      name: "Stock",
      button: true,
      cell: (row) => (
        <Link to={row.stock}>
          <button type="button" className="btn btn-block btn-success">
            Ver
          </button>
        </Link>
      ),
    })
  }


  const buscar = (e) => {
    const filtro = e.target.value.toLowerCase();
    const resultado = barriosOriginal.filter(
      (barrio) =>
        barrio.nombre.toLowerCase().includes(filtro) ||
        barrio.ciudad.toLowerCase().includes(filtro) ||
        barrio.direccion.toLowerCase().includes(filtro)
    );
    guardarBarrios(resultado);
  };

  if (showSpinner) {
    return <Spinner />;
  }

  return (
    <>
      <div className="modal fade" id="modal-archivos">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Generar lista de Precios</h4>
              <button type="button" className="close" data-dismiss="modal">
                <span>×</span>
              </button>
            </div>
            <FiltroListaPrecios nombreBarrio={nombreBarrio} token={token} idBarrio={idBarrio} />
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col-md-8">
              <h3 className="card-title mt-2 mb-2 mb-md-0">DESARROLLOS</h3>
            </div>
            {nivelRol >= 3 && (
              <div className="col-md-2">
                <Link to={`/lotes/nuevo`}>
                  <button type="button" className="btn btn-success btn-block mb-2 mb-md-0">Agregar Lote</button>
                </Link>
              </div>
            )}
            {nivelRol >= 3 && (
              <div className="col-md-2">
                <Link to={`/barrios/nuevo`}>
                  <button type="button" className="btn btn-success btn-block mb-2 mb-md-0">Nuevo</button>
                </Link>
              </div>
            )}
          </div>
        </div>
        <div className="card-body">
          <input
            placeholder="Buscar:"
            onChange={buscar}
            type="text"
            className="form-control mb-3"
          />
          {loadingInfo ? <Spinner /> : <DataTable columns={columns} data={barrios} pagination />}
        </div>
      </div>
    </>
  );
}

export default withRouter(Barrios);
